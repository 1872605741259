import { katTranslator } from '../../shared/i18n';

const strings = {
  kat_upload_file: {
    'en-US': 'Upload file',
    'ar-AE': 'تحميل ملف',
    'bn-IN': 'ফাইল আপলোড করুন',
    'de-DE': 'Datei hochladen',
    'es-ES': 'Subir archivo',
    'es-MX': 'Cargar archivo',
    'fr-BE': 'Charger le fichier',
    'fr-FR': 'Charger le fichier',
    'gu-IN': 'ફાઇલ અપલોડ કરો',
    'hi-IN': 'फ़ाइल अपलोड करें',
    'it-IT': 'Carica file',
    'ja-JP': 'ファイルをアップロード',
    'kn-IN': 'ಫೈಲ್ ಅಪ್‌ಲೋಡ್ ಮಾಡಿ',
    'ko-KR': '파일 업로드',
    'ml-IN': 'ഫയൽ അപ്‌ലോഡ് ചെയ്യുക',
    'mr-IN': 'फाइल अपलोड करा',
    'nl-BE': 'Bestand uploaden',
    'nl-NL': 'Bestand uploaden',
    'pl-PL': 'Prześlij plik',
    'pt-BR': 'Fazer upload do arquivo',
    'pt-PT': 'Carregar ficheiro',
    'sv-SE': 'Ladda upp fil',
    'ta-IN': 'ஃபைலை அப்லோடு செய்யவும்',
    'te-IN': 'ఫైల్‌ను అప్‌లోడ్ చేయండి',
    'th-TH': 'อัปโหลดไฟล์',
    'tr-TR': 'Dosya yükle',
    'vi-VN': 'Tải lên tệp',
    'zh-CN': '上传文件',
    'zh-TW': '上傳檔案',
  },
  kat_upload_files: {
    'en-US': 'Upload files',
    'ar-AE': 'تحميل الملفات',
    'bn-IN': 'ফাইলগুলি আপলোড করুন',
    'de-DE': 'Dateien hochladen',
    'es-ES': 'Subir archivos',
    'es-MX': 'Cargar archivos',
    'fr-BE': 'Charger des fichiers',
    'fr-FR': 'Charger des fichiers',
    'gu-IN': 'ફાઇલો અપલોડ કરો',
    'hi-IN': 'फ़ाइलें अपलोड करें',
    'it-IT': 'Carica file',
    'ja-JP': 'ファイルをアップロード',
    'kn-IN': 'ಫೈಲ್‌ಗಳನ್ನು ಅಪ್‌ಲೋಡ್ ಮಾಡಿ',
    'ko-KR': '여러 파일 업로드',
    'ml-IN': 'ഫയലുകൾ അപ്‌ലോഡ് ചെയ്യുക',
    'mr-IN': 'फाइल्स अपलोड करा',
    'nl-BE': 'Bestanden uploaden',
    'nl-NL': 'Bestanden uploaden',
    'pl-PL': 'Prześlij pliki',
    'pt-BR': 'Fazer upload de arquivos',
    'pt-PT': 'Carregar ficheiros',
    'sv-SE': 'Ladda upp filer',
    'ta-IN': 'ஃபைல்களை அப்லோடு செய்',
    'te-IN': 'ఫైల్‌లను అప్‌లోడ్‌ చేయండి',
    'th-TH': 'อัปโหลดไฟล์',
    'tr-TR': 'Dosya yükle',
    'vi-VN': 'Tải lên tệp',
    'zh-CN': '上传文件',
    'zh-TW': '上傳檔案',
  },
  kat_select_file: {
    'en-US': 'Select file',
    'ar-AE': 'تحديد ملف',
    'bn-IN': 'ফাইলটি নির্বাচন করুন',
    'de-DE': 'Datei auswählen',
    'es-ES': 'Seleccionar archivo',
    'es-MX': 'Seleccionar archivo',
    'fr-BE': 'Sélectionner un fichier',
    'fr-FR': 'Sélectionner un fichier',
    'gu-IN': 'ફાઇલ પસંદ કરો',
    'hi-IN': 'फ़ाइल चुनें',
    'it-IT': 'Seleziona un file',
    'ja-JP': 'ファイルを選択',
    'kn-IN': 'ಫೈಲ್ ಆಯ್ಕೆಮಾಡಿ',
    'ko-KR': '파일 선택',
    'ml-IN': 'ഫയൽ തിരഞ്ഞെടുക്കുക',
    'mr-IN': 'फाइल निवडा',
    'nl-BE': 'Bestand selecteren',
    'nl-NL': 'Bestand selecteren',
    'pl-PL': 'Wybierz plik',
    'pt-BR': 'Selecione o arquivo',
    'pt-PT': 'Selecionar ficheiro',
    'sv-SE': 'Välj fil',
    'ta-IN': 'ஃபைலைத் தேர்ந்தெடு',
    'te-IN': 'ఫైల్‌ను ఎంచుకోండి',
    'th-TH': 'เลือกไฟล์',
    'tr-TR': 'Dosya seç',
    'vi-VN': 'Chọn tệp',
    'zh-CN': '选择文件',
    'zh-TW': '選取檔案',
  },
  kat_select_files: {
    'en-US': 'Select files',
    'ar-AE': 'تحديد الملفات',
    'bn-IN': 'ফাইলগুলি নির্বাচন করুন',
    'de-DE': 'Dateien auswählen',
    'es-ES': 'Seleccionar archivos',
    'es-MX': 'Seleccionar archivos',
    'fr-BE': 'Sélectionner des fichiers',
    'fr-FR': 'Sélectionner des fichiers',
    'gu-IN': 'ફાઇલો પસંદ કરો',
    'hi-IN': 'फ़ाइलों को चुनें',
    'it-IT': 'Seleziona file',
    'ja-JP': 'ファイルを選択',
    'kn-IN': 'ಫೈಲ್‌ಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ',
    'ko-KR': '여러 파일 선택',
    'ml-IN': 'ഫയലുകൾ തിരഞ്ഞെടുക്കുക',
    'mr-IN': 'फाइल्स निवडा',
    'nl-BE': 'Bestanden selecteren',
    'nl-NL': 'Selecteer bestanden',
    'pl-PL': 'Wybierz pliki',
    'pt-BR': 'Selecione os arquivos',
    'pt-PT': 'Selecionar ficheiros',
    'sv-SE': 'Välj filer',
    'ta-IN': 'ஃபைல்களைத் தேர்ந்தெடு',
    'te-IN': 'ఫైల్‌లు ఎంచుకోండి',
    'th-TH': 'เลือกไฟล์หลายราย',
    'tr-TR': 'Dosyaları seç',
    'vi-VN': 'Chọn tệp',
    'zh-CN': '选择文件',
    'zh-TW': '選擇檔案',
  },
  kat_drag_file_here_to_attach: {
    'en-US': 'Drag file here to attach',
    'ar-AE': 'اسحب الملف إلى هنا لإرفاقه',
    'bn-IN': 'সংযুক্ত করতে ফাইলটিকে এখানে টেনে আনুন',
    'de-DE': 'Datei zum Anhängen hierher ziehen',
    'es-ES': 'Arrastra el archivo aquí para adjuntarlo',
    'es-MX': 'Arrastra el archivo aquí para adjuntarlo',
    'fr-BE': 'Faites glisser le fichier ici pour le joindre',
    'fr-FR': 'Faire glisser le fichier ici pour le joindre',
    'gu-IN': 'જોડવા માટે ફાઇલ અહીં ખેંચો',
    'hi-IN': 'अटैच करने के लिए फ़ाइल को यहां ड्रैग करें',
    'it-IT': 'Trascina il file qui per allegarlo',
    'ja-JP': 'ファイルをここにドラッグして添付します',
    'kn-IN': 'ಅಟ್ಯಾಚ್ ಮಾಡಲು ಫೈಲ್ ಅನ್ನು ಇಲ್ಲಿ ಡ್ರ್ಯಾಗ್ ಮಾಡಿ',
    'ko-KR': '여기로 파일을 끌어서 첨부하기',
    'ml-IN': 'അറ്റാച്ച് ചെയ്യാൻ ഫയൽ ഇവിടെ വലിച്ചിടുക',
    'mr-IN': 'अटॅच करण्यासाठी फाइल येथे ड्रॅग करा',
    'nl-BE': 'Bestand hiernaartoe slepen om bij te voegen',
    'nl-NL': 'Bestand hiernaartoe slepen om bij te voegen',
    'pl-PL': 'Przeciągnij plik tutaj, aby go załączyć',
    'pt-BR': 'Arraste o arquivo aqui para anexar',
    'pt-PT': 'Arraste o ficheiro aqui para anexar',
    'sv-SE': 'Dra filen hit för att bifoga',
    'ta-IN': 'இணைக்க இங்கே ஃபைலை இழுக்கவும்',
    'te-IN': 'అటాచ్ చేయడానికి ఫైల్‌ను ఇక్కడికి లాగండి',
    'th-TH': 'ลากไฟล์มาที่นี่เพื่อแนบ',
    'tr-TR': 'Eklemek için dosyayı buraya sürükleyin',
    'vi-VN': 'Kéo tệp vào đây để đính kèm',
    'zh-CN': '将文件拖到此处以附加',
    'zh-TW': '將檔案拖到此處以附加',
  },
  kat_drag_files_here_to_attach: {
    'en-US': 'Drag files here to attach',
    'ar-AE': 'اسحب الملفات إلى هنا لإرفاقها',
    'bn-IN': 'সংযুক্ত করতে ফাইলগুলিকে এখানে টেনে আনুন',
    'de-DE': 'Dateien zum Anhängen hierher ziehen',
    'es-ES': 'Arrastra los archivos aquí para añadirlos',
    'es-MX': 'Arrastra los archivos aquí para adjuntarlos',
    'fr-BE': 'Faites glisser les fichiers ici pour les joindre',
    'fr-FR': 'Faire glisser les fichiers ici pour les joindre',
    'gu-IN': 'ફાઇલોને અટેચ કરવા માટે અહીં ખેંચો',
    'hi-IN': 'अटैच करने के लिए फ़ाइलों को यहां ड्रैग करें',
    'it-IT': 'Trascina qui i file per allegarli',
    'ja-JP': 'ファイルをここにドラッグして添付します',
    'kn-IN': 'ಅಟ್ಯಾಚ್ ಮಾಡಲು ಫೈಲ್‌ಗಳನ್ನು ಇಲ್ಲಿಗೆ ಡ್ರ್ಯಾಗ್ ಮಾಡಿ',
    'ko-KR': '여기로 여러 파일을 끌어서 첨부하기',
    'ml-IN': 'അറ്റാച്ച് ചെയ്യാൻ ഇവിടെ ഫയലുകൾ വലിച്ചിടുക',
    'mr-IN': 'अटॅच करण्यासाठी फाइल्स येथे ड्रॅग करा',
    'nl-BE': 'Bestanden hiernaartoe slepen om bij te voegen',
    'nl-NL': 'Bestanden hiernaartoe slepen om bij te voegen',
    'pl-PL': 'Przeciągnij pliki tutaj, aby je załączyć',
    'pt-BR': 'Arraste os arquivos aqui para anexar',
    'pt-PT': 'Arraste os ficheiros aqui para anexar',
    'sv-SE': 'Dra filer hit för att bifoga',
    'ta-IN': 'ஃபைல்களை இணைக்க இங்கே இழுக்கவும்',
    'te-IN': 'అటాచ్ చేయడానికి ఫైల్‌లను ఇక్కడకి లాగండి',
    'th-TH': 'ลากไฟล์มาที่นี่เพื่อแนบ',
    'tr-TR': 'Dosyaları eklemek için buraya sürükleyin',
    'vi-VN': 'Kéo tệp vào đây để đính kèm',
    'zh-CN': '将文件拖动到此处以附加',
    'zh-TW': '將檔案拖到此處以附加',
  },
  kat_drag_file_here_to_upload: {
    'en-US': 'Drag file here to upload',
    'ar-AE': 'اسحب الملف إلى هنا للتحميل',
    'bn-IN': 'আপলোড করার জন্য ফাইলটিকে এখানে টেনে আনুন',
    'de-DE': 'Datei zum Hochladen hierher ziehen',
    'es-ES': 'Arrastra el archivo aquí para subirlo',
    'es-MX': 'Arrastrar el archivo aquí para cargar',
    'fr-BE': 'Faites glisser le fichier ici pour le charger',
    'fr-FR': 'Faire glisser le fichier ici pour le charger',
    'gu-IN': 'અપલોડ કરવા માટે ફાઇલ અહીં ખેંચો',
    'hi-IN': 'अपलोड करने के लिए फ़ाइल यहां ड्रैग करें',
    'it-IT': 'Trascina qui il file per caricarlo',
    'ja-JP': 'ファイルをここにドラッグしてアップロードします',
    'kn-IN': 'ಅಪ್‌ಲೋಡ್ ಮಾಡಲು ಫೈಲ್ ಅನ್ನು ಇಲ್ಲಿ ಡ್ರ್ಯಾಗ್ ಮಾಡಿ',
    'ko-KR': '여기로 파일을 끌어서 업로드',
    'ml-IN': 'അപ്‌ലോഡ് ചെയ്യാൻ ഫയൽ ഇവിടെ വലിച്ചിടുക',
    'mr-IN': 'अपलोड करण्यासाठी फाइल येथे ड्रॅग करा',
    'nl-BE': 'Bestand hiernaartoe slepen om te uploaden',
    'nl-NL': 'Bestand hiernaartoe slepen om te uploaden',
    'pl-PL': 'Przeciągnij plik tutaj, aby go przesłać',
    'pt-BR': 'Arraste o arquivo aqui para fazer upload',
    'pt-PT': 'Arraste o ficheiro para aqui para carregar',
    'sv-SE': 'Dra filen hit för att ladda upp',
    'ta-IN': 'அப்லோடு செய்வதற்கு இங்கே ஃபைலை இழுக்கவும்',
    'te-IN': 'అప్‌లోడ్ చేయడానికి ఫైల్‌ని ఇక్కడికి లాగండి',
    'th-TH': 'ลากไฟล์มาที่นี่เพื่ออัปโหลด',
    'tr-TR': 'Yüklemek için dosyayı buraya sürükleyin',
    'vi-VN': 'Kéo tệp vào đây để tải lên',
    'zh-CN': '将文件拖到此处进行上传',
    'zh-TW': '將檔案拖到此處以上傳',
  },
  kat_drag_files_here_to_upload: {
    'en-US': 'Drag files here to upload',
    'ar-AE': 'اسحب الملفات إلى هنا للتحميل',
    'bn-IN': 'আপলোড করতে এখানে ফাইলগুলি টেনে আনুন',
    'de-DE': 'Dateien zum Hochladen hierher ziehen',
    'es-ES': 'Arrastra los archivos aquí para subirlos',
    'es-MX': 'Arrastra los archivos aquí para cargar',
    'fr-BE': 'Faites glisser les fichiers ici pour les charger',
    'fr-FR': 'Faire glisser les fichiers ici pour les charger',
    'gu-IN': 'અપલોડ કરવા માટે ફાઇલને અહીં ખેંચો',
    'hi-IN': 'अपलोड करने के लिए फ़ाइलों को यहां ड्रैग करें',
    'it-IT': 'Trascina qui i file per caricarli',
    'ja-JP': 'ファイルをここにドラッグしてアップロードします',
    'kn-IN': 'ಅಪ್‌ಲೋಡ್ ಮಾಡಲು ಫೈಲ್‌ಗಳನ್ನು ಇಲ್ಲಿಗೆ ಎಳೆಯಿರಿ',
    'ko-KR': '여기로 여러 파일을 끌어서 업로드',
    'ml-IN': 'അപ്‌ലോഡ് ചെയ്യാൻ ഫയലുകൾ ഇവിടെ വലിച്ചിടുക',
    'mr-IN': 'अपलोड करण्यासाठी फाइल्स येथे ड्रॅग करा',
    'nl-BE': 'Bestanden hiernaartoe slepen om te uploaden',
    'nl-NL': 'Bestanden hiernaartoe slepen om te uploaden',
    'pl-PL': 'Przeciągnij pliki tutaj, aby je przesłać',
    'pt-BR': 'Arraste os arquivos para cá para fazer upload',
    'pt-PT': 'Arraste os ficheiros aqui para carregar',
    'sv-SE': 'Dra filer hit för att ladda upp dem',
    'ta-IN': 'ஃபைல்களை அப்லோடு செய்ய இங்கே இழுக்கவும்',
    'te-IN': 'అప్‌లోడ్ చేయడానికి ఫైల్‌లను ఇక్కడికి లాగండి',
    'th-TH': 'ลากไฟล์มาที่นี่เพื่ออัปโหลด',
    'tr-TR': 'Dosyaları yüklemek için buraya sürükleyin',
    'vi-VN': 'Kéo tệp vào đây để tải lên',
    'zh-CN': '将文件拖到此处进行上传',
    'zh-TW': '將檔案拖到此處以上傳',
  },
  kat_file_upload_unsuccessful: {
    'en-US': 'File upload was unsuccessful',
    'ar-AE': 'فشل تحميل الملف',
    'bn-IN': 'ফাইল আপলোড ব্যর্থ হয়েছিল',
    'de-DE': 'Der Upload der Datei ist fehlgeschlagen',
    'es-ES': 'No se ha podido subir el archivo',
    'es-MX': 'El archivo no se cargó correctamente',
    'fr-BE': 'Le chargement du fichier a échoué',
    'fr-FR': 'Le chargement du fichier a échoué',
    'gu-IN': 'ફાઇલ અપલોડ અસફળ રહ્યું હતું.',
    'hi-IN': 'फ़ाइल अपलोड नहीं की जा सकी',
    'it-IT': 'Upload del file non riuscito',
    'ja-JP': 'ファイルのアップロードに失敗しました',
    'kn-IN': 'ಫೈಲ್ ಅಪ್‌ಲೋಡ್ ವಿಫಲವಾಗಿದೆ',
    'ko-KR': '파일을 업로드하는 데 실패함',
    'ml-IN': 'ഫയൽ അപ്‌ലോഡ് വിജയകരമായില്ല',
    'mr-IN': 'फाइल अपलोड करणे अयशस्वी झाले',
    'nl-BE': 'Bestand uploaden is mislukt',
    'nl-NL': 'Bestand uploaden is mislukt',
    'pl-PL': 'Przesyłanie plików się nie powiodło',
    'pt-BR': 'O upload do arquivo falhou',
    'pt-PT': 'O carregamento do ficheiro não foi bem-sucedido',
    'sv-SE': 'Filuppladdningen misslyckades',
    'ta-IN': 'ஃபைல் அப்லோடு தோல்வியடைந்தது',
    'te-IN': 'ఫైల్ అప్‌లోడ్ విజయవంతం కాలేదు',
    'th-TH': 'การอัปโหลดไฟล์ไม่สำเร็จ',
    'tr-TR': 'Dosya yükleme başarısız oldu',
    'vi-VN': 'Tải lên tệp không thành công',
    'zh-CN': '文件上传失败',
    'zh-TW': '檔案上傳失敗',
  },
  kat_file_not_uploaded: {
    'en-US': 'File not uploaded',
    'ar-AE': 'لم يتم تحميل الملف',
    'bn-IN': 'ফাইলটি আপলোড করা হয়নি।',
    'de-DE': 'Datei nicht hochgeladen',
    'es-ES': 'No se ha subido el archivo',
    'es-MX': 'No se cargó el archivo',
    'fr-BE': 'Fichier non chargé',
    'fr-FR': 'Fichier non chargé',
    'gu-IN': 'ફાઇલ અપલોડ થઇ નથી.',
    'hi-IN': 'फ़ाइल अपलोड नहीं की गई',
    'it-IT': 'File non caricato',
    'ja-JP': 'ファイルがアップロードされていません',
    'kn-IN': 'ಫೈಲ್ ಅಪ್‌ಲೋಡ್ ಮಾಡಲಾಗಿಲ್ಲ.',
    'ko-KR': '파일이 업로드되지 않음',
    'ml-IN': 'ഫയൽ അപ്‌ലോഡ് ചെയ്തിട്ടില്ല',
    'mr-IN': 'फाइल अपलोड केली नाही',
    'nl-BE': 'Bestand niet geüpload',
    'nl-NL': 'Bestand niet geüpload',
    'pl-PL': 'Nie przesłano pliku',
    'pt-BR': 'Arquivo não carregado',
    'pt-PT': 'Ficheiro não carregado',
    'sv-SE': 'Filen har inte laddats upp',
    'ta-IN': 'ஃபைல் அப்லோடு செய்யப்படவில்லை',
    'te-IN': 'ఫైల్ అప్‌లోడ్ చేయబడలేదు',
    'th-TH': 'ไม่ได้อัปโหลดไฟล์',
    'tr-TR': 'Dosya yüklenmedi',
    'vi-VN': 'Chưa tải tệp lên',
    'zh-CN': '文件未上传',
    'zh-TW': '檔案未上傳',
  },
  kat_clear_all_files: {
    'en-US': 'Clear all files',
    'ar-AE': 'مسح كل الملفات',
    'bn-IN': 'সব ফাইল মুছে ফেলুন',
    'de-DE': 'Alle Dateien löschen',
    'es-ES': 'Borrar todos los archivos',
    'es-MX': 'Borrar todos los archivos',
    'fr-BE': 'Effacer tous les fichiers',
    'fr-FR': 'Effacer tous les fichiers',
    'gu-IN': 'બધી ફાઇલો કાઢી નાખો',
    'hi-IN': 'सभी फ़ाइलें हटाएं',
    'it-IT': 'Cancella tutti i file',
    'ja-JP': 'すべてのファイルをクリア',
    'kn-IN': 'ಎಲ್ಲಾ ಫೈಲ್‌ಗಳನ್ನು ತೆರವುಗೊಳಿಸಿ',
    'ko-KR': '모든 파일 지우기',
    'ml-IN': 'എല്ലാ ഫയലുകളും മായ്‌ക്കുക',
    'mr-IN': 'सर्व फाइल्स साफ करा',
    'nl-BE': 'Alle bestanden wissen',
    'nl-NL': 'Alle bestanden wissen',
    'pl-PL': 'Odznacz wszystkie pliki',
    'pt-BR': 'Limpar todos os arquivos',
    'pt-PT': 'Limpar todos os ficheiros',
    'sv-SE': 'Rensa alla filer',
    'ta-IN': 'அனைத்து ஃபைல்களையும் அழி',
    'te-IN': 'అన్ని ఫైల్‌లు క్లియర్ చేయండి',
    'th-TH': 'ล้างไฟล์ทั้งหมด',
    'tr-TR': 'Tüm dosyaları temizle',
    'vi-VN': 'Xóa tất cả các tệp',
    'zh-CN': '清除所有文件',
    'zh-TW': '清除所有檔案',
  },
  kat_attachment_required: {
    'en-US': 'An attachment is required',
    'ar-AE': 'مرفق مطلوب',
    'bn-IN': 'একটি সংযুক্তি প্রয়োজনীয়',
    'de-DE': 'Ein Anhang ist erforderlich',
    'es-ES': 'Se requiere un archivo adjunto',
    'es-MX': 'Se requiere un archivo adjunto',
    'fr-BE': 'Une pièce jointe est requise',
    'fr-FR': 'Une pièce jointe est requise',
    'gu-IN': 'જોડાણ જરૂરી છે',
    'hi-IN': 'अटैचमेंट ज़रूरी है',
    'it-IT': 'Un allegato è obbligatorio',
    'ja-JP': '添付ファイルが必要です',
    'kn-IN': 'ಲಗತ್ತಿನ ಅಗತ್ಯವಿದೆ',
    'ko-KR': '첨부 파일이 필요함',
    'ml-IN': 'ഒരു അറ്റാച്ച്‌മെൻ്റ് ആവശ്യമാണ്',
    'mr-IN': 'अटॅचमेंट आवश्यक आहे',
    'nl-BE': 'Een bijlage is vereist',
    'nl-NL': 'Een bijlage is vereist',
    'pl-PL': 'Wymagany jest załącznik',
    'pt-BR': 'Um anexo é obrigatório',
    'pt-PT': 'O anexo é obrigatório',
    'sv-SE': 'En bilaga krävs',
    'ta-IN': 'இணைப்பு தேவை',
    'te-IN': 'ఒక అటాచ్‌మెంట్ అవసరం',
    'th-TH': 'ต้องมีเอกสารแนบ',
    'tr-TR': 'Ek zorunludur',
    'vi-VN': 'Bắt buộc phải có tệp đính kèm',
    'zh-CN': '需要提供附件',
    'zh-TW': '需提供附件',
  },
  kat_over_max_size: {
    'en-US': 'Attachment size is over the limit',
    'ar-AE': 'يزيد حجم المرفق عن الحد المسموح به',
    'bn-IN': 'সংযুক্ত করা ফাইলটির আকার সীমা অতিক্রম করেছে',
    'de-DE': 'Der Anhang ist zu groß.',
    'es-ES': 'El tamaño del archivo adjunto supera el límite',
    'es-MX': 'El tamaño del archivo adjunto supera el límite',
    'fr-BE': 'La taille de la pièce jointe est supérieure à la limite',
    'fr-FR': 'La taille de la pièce jointe dépasse la limite',
    'gu-IN': 'અટેચમેન્ટની સાઇઝ મર્યાદાથી વધુ છે',
    'hi-IN': 'अटैचमेंट का साइज़, लिमिट से ज़्यादा है',
    'it-IT': "La dimensione dell'allegato è superiore al limite",
    'ja-JP': '添付ファイルのサイズが制限を超えています',
    'kn-IN': 'ಲಗತ್ತಿನ ಗಾತ್ರ ಮಿತಿ ಮೀರಿದೆ',
    'ko-KR': '첨부 파일 크기가 한도를 초과함',
    'ml-IN': 'അറ്റാച്ച്‌മെൻ്റ് വലുപ്പം പരിധിയിൽ അധികമാണ്',
    'mr-IN': 'अटॅचमेंटचा आकार मर्यादेपेक्षा जास्त आहे',
    'nl-BE': 'De bijlage is te groot',
    'nl-NL': 'De bestandsgrootte van de bijlage is te groot',
    'pl-PL': 'Rozmiar załącznika przekracza limit',
    'pt-BR': 'O tamanho do anexo ultrapassa o limite',
    'pt-PT': 'O tamanho do anexo está acima do limite',
    'sv-SE': 'Bilagans storlek är över gränsen',
    'ta-IN': 'இணைப்பு அளவு வரம்புக்கு மேல் உள்ளது',
    'te-IN': 'అటాచ్‌మెంట్ పరిమాణం పరిమితికి మించి ఉంది',
    'th-TH': 'ขนาดของไฟล์แนบเกินขีดจำกัด',
    'tr-TR': 'Ek boyutu sınırı aştı',
    'vi-VN': 'Kích thước tệp đính kèm vượt quá giới hạn',
    'zh-CN': '附件大小超过限制',
    'zh-TW': '附件大小超過限制',
  },
  kat_unsupported_file_attached: {
    'en-US': 'Unsupported file was attached',
    'ar-AE': 'تم إرفاق ملف غير مدعوم',
    'bn-IN': 'অসমর্থিত ফাইলটিকে সংযুক্ত করা হয়েছিল',
    'de-DE': 'Eine nicht unterstützte Datei wurde angehängt',
    'es-ES': 'Se ha adjuntado un archivo no admitido',
    'es-MX': 'Se adjuntó un archivo no compatible',
    'fr-BE': 'Un fichier non pris en charge a été joint',
    'fr-FR': 'Un fichier non pris en charge a été joint',
    'gu-IN': 'અસમર્થિત ફાઇલ જોડાયેલ હતી',
    'hi-IN': 'अनसपोर्टेड फ़ाइल अटैच की गई थी',
    'it-IT': 'File non supportato allegato',
    'ja-JP': 'サポートされていないファイルが添付されました',
    'kn-IN': 'ಬೆಂಬಲವಿಲ್ಲದ ಫೈಲ್ ಅನ್ನು ಅಟ್ಯಾಚ್ ಮಾಡಲಾಗಿದೆ',
    'ko-KR': '지원되지 않는 파일이 첨부됨',
    'ml-IN': 'പിന്തുണയ്ക്കാത്ത ഫയൽ അറ്റാച്ച് ചെയ്തു',
    'mr-IN': 'असमर्थित फाइल अटॅच केली होती',
    'nl-BE': 'Niet-ondersteund bestand is bijgevoegd',
    'nl-NL': 'Niet-ondersteund bestand is bijgevoegd',
    'pl-PL': 'Załączono nieobsługiwany plik',
    'pt-BR': 'Um arquivo não suportado foi anexado',
    'pt-PT': 'Foi anexado um ficheiro não suportado',
    'sv-SE': 'Bifogad fil stöds inte',
    'ta-IN': 'ஆதரிக்கப்படாத ஃபைல் இணைக்கப்பட்டது',
    'te-IN': 'మద్దతు లేని ఫైల్ జతచేయబడింది',
    'th-TH': 'ระบบไม่รองรับไฟล์ที่แนบ',
    'tr-TR': 'Desteklenmeyen dosya eklenmiş',
    'vi-VN': 'Đã đính kèm tệp không được hỗ trợ',
    'zh-CN': '附加了不受支持的文件',
    'zh-TW': '附加檔案為不支援的格式',
  },
  kat_title_delete_this_file: {
    'en-US': 'Delete this file?',
    'ar-AE': 'هل تريد حذف هذا الملف؟',
    'bn-IN': 'এই ফাইলটি কি মুছে ফেলবেন?',
    'de-DE': 'Diese Datei löschen?',
    'en-IN': 'Do you want to delete this file?',
    'es-ES': '¿Quieres eliminar este archivo?',
    'es-MX': '¿Quieres eliminar este archivo?',
    'fr-BE': 'Supprimer ce fichier ?',
    'fr-FR': 'Supprimer ce fichier ?',
    'gu-IN': 'આ ફાઇલ ડીલિટ કરવી છે?',
    'hi-IN': 'यह फ़ाइल डिलीट करें?',
    'it-IT': 'Elimina questo file?',
    'ja-JP': 'このファイルを削除しますか？',
    'kn-IN': 'ಈ ಫೈಲ್ ಅನ್ನು ಡಿಲೀಟ್ ಮಾಡಬೇಕೆ?',
    'ko-KR': '이 파일을 삭제하시겠습니까?',
    'ml-IN': 'ഈ ഫയൽ ഇല്ലാതാക്കണോ?',
    'mr-IN': 'ही फाइल हटवायची?',
    'nl-BE': 'Dit bestand verwijderen?',
    'nl-NL': 'Dit bestand verwijderen?',
    'pl-PL': 'Usunąć ten plik?',
    'pt-BR': 'Excluir este arquivo?',
    'pt-PT': 'Apagar este ficheiro?',
    'sv-SE': 'Radera den här filen?',
    'ta-IN': 'இந்த ஃபைலை நீக்கவா?',
    'te-IN': 'ఈ ఫైల్‌ని తొలగించాలా?',
    'th-TH': 'ให้ลบไฟล์นี้ไหม',
    'tr-TR': 'Bu dosya silinsin mi?',
    'vi-VN': 'Xóa tệp này?',
    'zh-CN': '删除此文件？',
    'zh-TW': '是否刪除此檔案？',
  },
  kat_file_will_be_deleted: {
    'en-US': '{file} file will be deleted.',
    'ar-AE': 'سيتم حذف ملف {file}.',
    'bn-IN': '{file}ফাইল মুছে ফেলা হবে।',
    'de-DE': 'Die Datei {file} wird gelöscht.',
    'es-ES': 'El archivo {file} se eliminará.',
    'es-MX': 'El archivo {file} se eliminará.',
    'fr-BE': 'Le fichier {file} sera supprimé.',
    'fr-FR': 'Le fichier {file} sera supprimé.',
    'gu-IN': '{file}ફાઇલ કાઢી નાખવામાં આવશે.',
    'hi-IN': '{file} फ़ाइल डिलीट की जाएगी.',
    'it-IT': '{file} verrà eliminato.',
    'ja-JP': '{file}ファイルは削除されます。',
    'kn-IN': '{file} ಫೈಲ್ ಅನ್ನು ಡಿಲೀಟ್ ಮಾಡಲಾಗುತ್ತದೆ.',
    'ko-KR': '{file} 파일이 삭제됩니다.',
    'ml-IN': '{file} ഫയൽ ഇല്ലാതാക്കപ്പെടും.',
    'mr-IN': '{file} फाइल हटवली जाईल.',
    'nl-BE': 'Bestand {file} wordt verwijderd.',
    'nl-NL': 'Bestand {file} wordt verwijderd.',
    'pl-PL': 'Plik {file} zostanie usunięty.',
    'pt-BR': 'O arquivo {file} será excluído.',
    'pt-PT': '{file} ficheiro será eliminado.',
    'sv-SE': '{file} filen raderas.',
    'ta-IN': '{file} ஃபைல் நீக்கப்படும்.',
    'te-IN': '{file} ఫైలు తొలగించబడుతుంది.',
    'th-TH': '{file} ไฟล์จะถูกลบออก',
    'tr-TR': '{file} dosya silinecek.',
    'vi-VN': '{file} tệp sẽ bị xóa.',
    'zh-CN': '{file} 文件将被删除。',
    'zh-TW': '即將刪除 {file} 檔案。',
  },
  kat_title_delete_all_files: {
    'en-US': 'Delete all files?',
    'ar-AE': 'هل تريد حذف كل الملفات؟',
    'bn-IN': 'সব ফাইল মুছে ফেলবেন?',
    'de-DE': 'Alle Dateien löschen?',
    'en-IN': 'Do you want to delete all files?',
    'es-ES': '¿Quieres eliminar todos los archivos?',
    'es-MX': '¿Quieres eliminar todos los archivos?',
    'fr-BE': 'Supprimer tous les fichiers ?',
    'fr-FR': 'Supprimer tous les fichiers ?',
    'gu-IN': 'બધી ફાઇલો કાઢી નાખવી છે?',
    'hi-IN': 'सभी फ़ाइलों को डिलीट करें?',
    'it-IT': 'Eliminare tutti i file?',
    'ja-JP': 'すべてのファイルを削除しますか？',
    'kn-IN': 'ಎಲ್ಲಾ ಫೈಲ್‌ಗಳನ್ನು ಅಳಿಸಬೇಕೆ?',
    'ko-KR': '모든 파일을 삭제하시겠습니까?',
    'ml-IN': 'എല്ലാ ഫയലുകളും ഇല്ലാതാക്കണോ?',
    'mr-IN': 'सर्व फाइल्स हटवायच्या?',
    'nl-BE': 'Alle bestanden verwijderen?',
    'nl-NL': 'Alle bestanden verwijderen?',
    'pl-PL': 'Usunąć wszystkie pliki?',
    'pt-BR': 'Excluir todos os arquivos?',
    'pt-PT': 'Eliminar todos os ficheiros?',
    'sv-SE': 'Radera alla filer?',
    'ta-IN': 'அனைத்து ஃபைல்களையும் நீக்கவா?',
    'te-IN': 'అన్ని ఫైల్‌లను తొలగించాలా?',
    'th-TH': 'ให้ลบไฟล์ทั้งหมดหรือไม่',
    'tr-TR': 'Tüm dosyalar silinsin mi?',
    'vi-VN': 'Xóa tất cả các tệp?',
    'zh-CN': '删除所有文件？',
    'zh-TW': '是否刪除所有檔案？',
  },
  kat_files_will_be_deleted: {
    'en-US': 'All files will be deleted.',
    'ar-AE': 'سيتم حذف جميع الملفات.',
    'bn-IN': 'সমস্ত ফাইল মুছে ফেলা হবে।',
    'de-DE': 'Alle Dateien werden gelöscht.',
    'es-ES': 'Se eliminarán todos los archivos.',
    'es-MX': 'Se eliminarán todos los archivos.',
    'fr-BE': 'Tous les fichiers seront supprimés.',
    'fr-FR': 'Tous les fichiers seront supprimés.',
    'gu-IN': 'બધી ફાઇલો કાઢી નાખવામાં આવશે.',
    'hi-IN': 'सभी फ़ाइलें डिलीट हो जाएंगी.',
    'it-IT': 'Tutti i file verranno eliminati.',
    'ja-JP': 'すべてのファイルが削除されます。',
    'kn-IN': 'ಎಲ್ಲಾ ಫೈಲ್‌ಗಳನ್ನು ಅಳಿಸಲಾಗುತ್ತದೆ',
    'ko-KR': '모든 파일이 삭제됩니다.',
    'ml-IN': 'എല്ലാ ഫയലുകളും ഇല്ലാതാക്കപ്പെടും.',
    'mr-IN': 'सर्व फाइल्स हटवल्या जातील.',
    'nl-BE': 'Alle bestanden worden verwijderd.',
    'nl-NL': 'Alle bestanden worden verwijderd.',
    'pl-PL': 'Wszystkie pliki zostaną usunięte.',
    'pt-BR': 'Todos os arquivos serão excluídos.',
    'pt-PT': 'Todos os ficheiros serão eliminados.',
    'sv-SE': 'Alla filer raderas.',
    'ta-IN': 'அனைத்து ஃபைல்களும் நீக்கப்படும்.',
    'te-IN': 'అన్ని ఫైల్‌లు తొలగించబడతాయి.',
    'th-TH': 'ไฟล์ทั้งหมดจะถูกลบ',
    'tr-TR': 'Tüm dosyalar silinecek.',
    'vi-VN': 'Tất cả các tệp sẽ bị xóa.',
    'zh-CN': '所有文件将被删除。',
    'zh-TW': '即將刪除所有檔案。',
  },
  kat_title_replace_this_file: {
    'en-US': 'Replace this file?',
    'ar-AE': 'أتريد استبدال هذا الملف؟',
    'bn-IN': 'এই ফাইলটি কি রিপ্লেস করবেন?',
    'de-DE': 'Diese Datei ersetzen?',
    'en-IN': 'Do you want to replace this file?',
    'es-ES': '¿Sustituir este archivo?',
    'es-MX': '¿Reemplazar este archivo?',
    'fr-BE': 'Remplacer ce fichier ?',
    'fr-FR': 'Remplacer ce fichier ?',
    'gu-IN': 'આ ફાઇલને બદલવી છે?',
    'hi-IN': 'यह फ़ाइल रिप्लेस करें?',
    'it-IT': 'Sostituire questo file?',
    'ja-JP': 'このファイルを置き換えますか？',
    'kn-IN': 'ಈ ಫೈಲ್ ಅನ್ನು ಬದಲಾಯಿಸಬೇಕೆ?',
    'ko-KR': '이 파일을 대체하시겠습니까?',
    'ml-IN': 'ഈ ഫയൽ റീപ്ലേസ് ചെയ്യണോ?',
    'mr-IN': 'ही फाइल बदलायची?',
    'nl-BE': 'Dit bestand vervangen?',
    'nl-NL': 'Dit bestand vervangen?',
    'pl-PL': 'Zastąpić ten plik?',
    'pt-BR': 'Substituir este arquivo?',
    'pt-PT': 'Substituir este ficheiro?',
    'sv-SE': 'Ersätt den här filen?',
    'ta-IN': 'இந்த ஃபைலை மாற்றவா?',
    'te-IN': 'ఈ ఫైల్‌ని భర్తీ చేయాలా?',
    'th-TH': 'ให้แทนที่ไฟล์นี้ไหม',
    'tr-TR': 'Bu dosya değiştirilsin mi?',
    'vi-VN': 'Thay thế tệp này?',
    'zh-CN': '替换此文件？',
    'zh-TW': '是否取代此檔案？',
  },
  kat_file_will_be_replaced: {
    'en-US': '{file} will be replaced with a new file.',
    'ar-AE': 'سيتم استبدال {file} بملف جديد.',
    'bn-IN': '{file}-কে একটি নতুন ফাইল দিয়ে রিপ্লেস করা হবে।',
    'de-DE': '{file} wird durch eine neue Datei ersetzt.',
    'es-ES': '{file} se sustituirá por un nuevo archivo.',
    'es-MX': '{file} se reemplazará por un nuevo archivo.',
    'fr-BE': '{file} sera remplacé par un nouveau fichier.',
    'fr-FR': '{file} sera remplacé par un nouveau fichier.',
    'gu-IN': '{file}નવી ફાઇલ સાથે બદલવામાં આવશે.',
    'hi-IN': '{file} को नई फ़ाइल से रिप्लेस किया जाएगा.',
    'it-IT': '{file} verrà sostituito con un nuovo file.',
    'ja-JP': '{file}は新しいファイルに置き換えられます。',
    'kn-IN': '{file} ಅನ್ನು ಹೊಸ ಫೈಲ್ ಮೂಲಕ ಬದಲಾಯಿಸಲಾಗುತ್ತದೆ.',
    'ko-KR': '{file} 파일이 새 파일로 대체됩니다.',
    'ml-IN': 'പുതിയൊരു ഫയൽ ഉപയോഗിച്ച് {file} റീപ്ലേസ് ചെയ്യപ്പെടും.',
    'mr-IN': '{file} ला नवीन फाइलसह बदलले जाईल.',
    'nl-BE': '{file} wordt vervangen door een nieuw bestand.',
    'nl-NL': '{file} wordt vervangen door een nieuw bestand.',
    'pl-PL': 'Plik {file} zostanie zastąpiony nowym plikiem.',
    'pt-BR': '{file} será substituído por um novo arquivo.',
    'pt-PT': '{file} será substituído por um novo ficheiro.',
    'sv-SE': '{file} ersätts med en ny fil.',
    'ta-IN': '{file} ஒரு புதிய ஃபைலுடன் மாற்றப்படும்.',
    'te-IN': '{file} కొత్త ఫైల్‌తో భర్తీ చేయబడుతుంది.',
    'th-TH': '{file} จะถูกแทนที่ด้วยไฟล์ใหม่',
    'tr-TR': '{file} yeni bir dosya ile değiştirilecek.',
    'vi-VN': '{file} sẽ được thay thế bằng một tệp mới.',
    'zh-CN': '{file} 将被替换为新文件。',
    'zh-TW': '{file} 即將更換為新檔案。',
  },
  kat_title_replace_all_files: {
    'en-US': 'Replace {number} files?',
    'ar-AE': 'هل تريد استبدال {number} من الملفات؟',
    'bn-IN': '{number} ফাইলগুলি কি রিপ্লেস করবেন?',
    'de-DE': '{number} Dateien ersetzen?',
    'en-IN': 'Do you want to replace {number} files?',
    'es-ES': '¿Sustituir {number} archivos?',
    'es-MX': '¿Reemplazar {number} archivos?',
    'fr-BE': 'Remplacer {number} fichiers ?',
    'fr-FR': 'Remplacer {number} fichiers ?',
    'gu-IN': '{number}ફાઇલો બદલવી છે?',
    'hi-IN': '{number} फ़ाइलों को रिप्लेस करें?',
    'it-IT': 'Sostituire {number} file?',
    'ja-JP': '{number}個のファイルを置き換えますか？',
    'kn-IN': '{number} ಫೈಲ್‌ಗಳನ್ನು ಬದಲಾಯಿಸಬೇಕೆ?',
    'ko-KR': '{number}개의 파일을 대체하시겠습니까?',
    'ml-IN': '{number} ഫയലുകൾ റീപ്ലേസ് ചെയ്യണോ?',
    'mr-IN': '{number} फाइल्स बदलायच्या?',
    'nl-BE': '{number} bestanden vervangen?',
    'nl-NL': '{number} bestanden vervangen?',
    'pl-PL': 'Zastąpić pliki ({number})?',
    'pt-BR': 'Substituir {number} arquivos?',
    'pt-PT': 'Substituir {number} ficheiros?',
    'sv-SE': 'Ersätt {number} filer?',
    'ta-IN': '{number} ஃபைல்களை மாற்றவா?',
    'te-IN': '{number}ఫైల్‌లను భర్తీ చేయాలా?',
    'th-TH': 'ให้แทนที่ {number} ไฟล์หรือไม่',
    'tr-TR': '{number} dosya değiştirilsin mi?',
    'vi-VN': 'Thay thế {number} tệp?',
    'zh-CN': '替换 {number} 个文件？',
    'zh-TW': '是否取代 {number} 個檔案？',
  },
  kat_files_will_be_replaced: {
    'en-US': '{file} will be replaced with new files.',
    'ar-AE': 'سيتم استبدال {file} بملفات جديدة.',
    'bn-IN': '{file}-কে নতুন ফাইলগুলি দিয়ে রিপ্লেস করা হবে।',
    'de-DE': '{file} werden durch neue Dateien ersetzt.',
    'es-ES': '{file} se sustituirán por nuevos archivos.',
    'es-MX': '{file} se reemplazará por nuevos archivos.',
    'fr-BE': '{file} sera remplacé par de nouveaux fichiers.',
    'fr-FR': '{file} sera remplacé par de nouveaux fichiers.',
    'gu-IN': '{file}નવી ફાઇલો સાથે બદલવામાં આવશે.',
    'hi-IN': '{file} को नई फ़ाइलों से रिप्लेस किया जाएगा.',
    'it-IT': '{file} verranno sostituiti con nuovi file.',
    'ja-JP': '{file}は新しいファイルに置き換えられます。',
    'kn-IN': '{file} ಅನ್ನು ಹೊಸ ಫೈಲ್‌ಗಳ ಮೂಲಕ ಬದಲಾಯಿಸಲಾಗುತ್ತಿದೆ.',
    'ko-KR': '{file} 파일이 새 파일로 대체됩니다.',
    'ml-IN': 'പുതിയ ഫയലുകൾ ഉപയോഗിച്ച് {file} റീപ്ലേസ് ചെയ്യപ്പെടും.',
    'mr-IN': '{file} ला नवीन फाइल्ससह बदलले जाईल.',
    'nl-BE': '{file} wordt vervangen door nieuwe bestanden.',
    'nl-NL': '{file} worden vervangen door nieuwe bestanden.',
    'pl-PL': 'Pliki {file} zostaną zastąpione nowymi plikami.',
    'pt-BR': '{file} será substituído por novos arquivos.',
    'pt-PT': '{file} será substituído por novos ficheiros.',
    'sv-SE': '{file} ersätts med nya filer.',
    'ta-IN': '{file} புதிய ஃபைல்களுடன் மாற்றப்படும்.',
    'te-IN': '{file} కొత్త ఫైల్‌లతో భర్తీ చేయబడుతుంది.',
    'th-TH': '{file} จะถูกแทนที่ด้วยไฟล์ใหม่',
    'tr-TR': '{file} yeni dosyalarla değiştirilecek.',
    'vi-VN': '{file} sẽ được thay thế bằng các tệp mới.',
    'zh-CN': '{file} 将被替换为新文件。',
    'zh-TW': '{file} 即將更換為新檔案。',
  },
  kat_label_cancel: {
    'en-US': 'Cancel',
    'ar-AE': 'إلغاء',
    'bn-IN': 'বাতিল করুন',
    'de-DE': 'Abbrechen',
    'es-ES': 'Cancelar',
    'es-MX': 'Cancelar',
    'fr-BE': 'Annuler',
    'fr-FR': 'Annuler',
    'gu-IN': 'કેન્સલ કરો',
    'hi-IN': 'कैंसल करें',
    'it-IT': 'Annulla',
    'ja-JP': 'キャンセル',
    'kn-IN': 'ರದ್ದು ಮಾಡಿ',
    'ko-KR': '취소',
    'ml-IN': 'റദ്ദാക്കുക',
    'mr-IN': 'रद्द करा',
    'nl-BE': 'Annuleren',
    'nl-NL': 'Annuleren',
    'pl-PL': 'Anuluj',
    'pt-BR': 'Cancelar',
    'pt-PT': 'Cancelar',
    'sv-SE': 'Avbryt',
    'ta-IN': 'ரத்துசெய்',
    'te-IN': 'రద్దు చేయండి',
    'th-TH': 'ยกเลิก',
    'tr-TR': 'İptal et',
    'vi-VN': 'Hủy',
    'zh-CN': '取消',
    'zh-TW': '取消',
  },
  kat_label_delete: {
    'en-US': 'Delete',
    'ar-AE': 'حذف',
    'bn-IN': 'মুছুন',
    'de-DE': 'Löschen',
    'es-ES': 'Eliminar',
    'es-MX': 'Eliminar',
    'fr-BE': 'Supprimer',
    'fr-FR': 'Supprimer',
    'gu-IN': 'ડિલીટ કરો',
    'hi-IN': 'डिलीट करें',
    'it-IT': 'Elimina',
    'ja-JP': '削除',
    'kn-IN': 'ಡಿಲೀಟ್ ಮಾಡಿ',
    'ko-KR': '삭제',
    'ml-IN': 'ഇല്ലാതാക്കുക',
    'mr-IN': 'हटवा',
    'nl-BE': 'Verwijderen',
    'nl-NL': 'Verwijderen',
    'pl-PL': 'Usuń',
    'pt-BR': 'Excluir',
    'pt-PT': 'Eliminar',
    'sv-SE': 'Radera',
    'ta-IN': 'நீக்கு',
    'te-IN': 'తొలగించండి',
    'th-TH': 'ลบ',
    'tr-TR': 'Sil',
    'vi-VN': 'Xóa',
    'zh-CN': '删除',
    'zh-TW': '刪除',
  },
  kat_label_replace: {
    'en-US': 'Replace',
    'ar-AE': 'استبدال',
    'bn-IN': 'রিপ্লেস করুন',
    'de-DE': 'Ersetzen',
    'es-ES': 'Reemplazar',
    'es-MX': 'Reemplazar',
    'fr-BE': 'Remplacer',
    'fr-FR': 'Remplacer',
    'gu-IN': 'રિપ્લેસ કરો',
    'hi-IN': 'रिप्लेस करें',
    'it-IT': 'Sostituisci',
    'ja-JP': '差し替え',
    'kn-IN': 'ಬದಲಾಯಿಸಿ',
    'ko-KR': '대체하기',
    'ml-IN': 'റീപ്ലേസ് ചെയ്യുക',
    'mr-IN': 'बदला',
    'nl-BE': 'Vervangen',
    'nl-NL': 'Vervangen',
    'pl-PL': 'Zastąp',
    'pt-BR': 'Substituir',
    'pt-PT': 'Substituir',
    'sv-SE': 'Ersätt',
    'ta-IN': 'மாற்று',
    'te-IN': 'భర్తీ చేయండి',
    'th-TH': 'แทนที่',
    'tr-TR': 'Değiştir',
    'vi-VN': 'Thay thế',
    'zh-CN': '替换',
    'zh-TW': '取代',
  },
};

export default katTranslator(strings);
