import { katTranslator } from '../../shared/i18n';

const strings = {
  "kat-alert-dismiss": {
    "en-US": "dismiss",
    "ar-AE": "تجاهل",
    "bn-IN": "বরখাস্ত করুন",
    "de-DE": "Verwerfen",
    "es-ES": "Omitir",
    "es-MX": "descartar",
    "fr-BE": "Ignorer",
    "fr-FR": "ignorer",
    "gu-IN": "બરતરફ કરો",
    "hi-IN": "खारिज करें",
    "it-IT": "Ignora",
    "ja-JP": "解除",
    "kn-IN": "ವಜಾಗೊಳಿಸಿ",
    "ko-KR": "무시",
    "ml-IN": "തള്ളിക്കളയുക",
    "mr-IN": "काढून टाका",
    "nl-BE": "negeren",
    "nl-NL": "negeren",
    "pl-PL": "zamknij",
    "pt-BR": "ignorar",
    "pt-PT": "ignorar",
    "sv-SE": "avfärda",
    "ta-IN": "நிராகரி",
    "te-IN": "రద్దు చేయండి",
    "th-TH": "ปิด",
    "tr-TR": "kapat",
    "vi-VN": "bỏ qua",
    "zh-CN": "忽略",
    "zh-TW": "關閉"
  }
}; 

export default katTranslator(strings);
    