import React from 'react';
import { KatFileUpload, KatProgress } from '@amzn/katal-react';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import { DocumentMetadata } from 'src/model/DocumentMetadata';
import { DocumentType } from 'src/model/DocumentType';
import {
  MAX_FILE_UPLOAD_SIZE_IN_BYTES,
  ACCEPTED_FILE_UPLOAD_FORMATS,
} from 'src/constants';
import { logInfo } from 'src/utils/logger/LoggerUtil';

interface DocumentUploadUnitProps {
  id: string;
  title: string;
  description: string;
  documentMetadata?: DocumentMetadata;
  documentType: DocumentType;
  handleAttachedFiles: (e: File[], documentType: DocumentType) => void;
  handleReplacedFiles: (e: File[], documentType: DocumentType) => void;
  handleRemovedFiles: (e: File[], documentType: DocumentType) => void;
  attachmentError: boolean;
}

export const DocumentUploadUnitComponent = (
  props: DocumentUploadUnitProps & WithBundleProps,
) => {
  logInfo(`Rendering DocumentUploadUnitComponent UI elements`);
  const { id, title, description, documentMetadata, bundle } = props;
  return (
    <div id={id} className="document-upload-unit-padding">
      <div>
        <h3 className="title-secondary-padding inline-block">{title}</h3>
        {documentMetadata?.latestUploadTimestamp && (
          <div
            id={`${id}-doc-upload-timestamp`}
            className="document-upload-timestamp-status inline-block"
          >
            <KatProgress
              className="inline-block green-success-status"
              size="small"
              state="success"
              type="circular"
            />
            <span className="text-secondary inline-block document-upload-timestamp">
              {bundle.getMessage('document-upload-page-last-upload-time-text') +
                ' ' +
                new Date(documentMetadata.latestUploadTimestamp).toLocaleString(
                  document.documentElement.lang,
                )}
            </span>
          </div>
        )}
      </div>
      <span>{description}</span>
      <div className="document-upload-component-padding">
        <KatFileUpload
          id={`${id}-file-upload`}
          variant="small"
          multiple={false}
          maxSize={MAX_FILE_UPLOAD_SIZE_IN_BYTES}
          accept={ACCEPTED_FILE_UPLOAD_FORMATS}
          onFilesAttached={(e) =>
            props.handleAttachedFiles(e.detail.files, props.documentType)
          }
          onFilesReplaced={(e) =>
            props.handleReplacedFiles(e.detail.newFiles, props.documentType)
          }
          onFilesRemoved={(e) =>
            props.handleRemovedFiles(e.detail.files, props.documentType)
          }
        >
          <div slot="constraints-message">
            {bundle.getMessage('document-upload-file-constraint-message')}
          </div>
        </KatFileUpload>
        {props.attachmentError && (
          <div className="attachment-error-message">
            {bundle.getMessage('file-attachment-error-generic')}
          </div>
        )}
      </div>
    </div>
  );
};

export const DocumentUploadUnit = withBundle('component.documentUploadUnit')(
  DocumentUploadUnitComponent,
);
