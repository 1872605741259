import React, { useRef } from 'react';
import { KatButton, KatAlert } from '@amzn/katal-react';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import { MarioUtil } from 'src/utils/MarioUtil';
import { logError, logInfo } from 'src/utils/logger/LoggerUtil';
import {
  INITIATE_INVESTIGATION,
  DEFAULT_API_RETRY_COUNT,
  INITIATE_INVESTIGATION_API_TIMEOUT,
} from 'src/constants';
import { AjaxResponse } from 'src/utils/ajax/AjaxHandler';
import { MarioAjaxHandler } from 'src/utils/ajax/MarioAjaxHandler';
import {
  publishCounterMonitorMetric,
  publishTimerMonitorMetric,
} from 'src/utils/MetricUtil';

export const LandingPageComponent = (props: WithBundleProps) => {
  logInfo(`Loading LandingPageComponent`);
  const { bundle } = props;
  const landingPageWidget = useRef<any>(null);
  const [shouldDisplayErrorMessage, setShouldDisplayErrorMessage] =
    React.useState<boolean>(false);

  const PMET_METHOD_NAME = 'LandingPage';
  const PMET_RENDER_METRIC_NAME = 'render';
  const PMET_INITIATE_INVESTIGATION_BUTTON_CLICK_METRIC_NAME =
    'initiateinvestigationbuttonclick';
  const PMET_INITIATE_INVESTIGATION_BUTTON_CLICK_SUCCESS_METRIC_NAME =
    'initiateinvestigationbuttonclicksuccess';
  const PMET_INITIATE_INVESTIGATION_BUTTON_CLICK_FAILURE_METRIC_NAME =
    'initiateinvestigationbuttonclickfailure';
  const PMET_INITIATE_INVESTIGATION_BUTTON_CLICK_LATENCY_METRIC_NAME =
    'initiateinvestigationbuttonclicklatency';
  const PMET_INITIATE_INVESTIGATION_BUTTON_CLICK_VALIDATION_EXCEPTION_METRIC_NAME =
    'initiateinvestigationbuttonclickvalidationexception';
  const PMET_INITIATE_INVESTIGATION_API_RETRY_COUNT =
    'initiateinvestigationapiretrycount';
  const PMET_INITIATE_INVESTIGATION_500_STATUS_CODE_COUNT =
    'initiateinvestigation500httpstatuscodecount';
  const PMET_INITIATE_INVESTIGATION_API_TIMEOUT_COUNT =
    'initiateinvestigationapitimeoutcount';
  const PMET_INITIATE_INVESTIGATION_API_ABORT_ERROR_COUNT =
    'initiateinvestigationapiaborterrorcount';

  const processInitiateInvestigationResponse = (
    response: AjaxResponse,
    startTime: number,
  ) => {
    publishTimerMonitorMetric(
      PMET_METHOD_NAME,
      PMET_INITIATE_INVESTIGATION_BUTTON_CLICK_LATENCY_METRIC_NAME,
      startTime,
    );
    if (response.ok) {
      publishCounterMonitorMetric(
        PMET_METHOD_NAME,
        PMET_INITIATE_INVESTIGATION_BUTTON_CLICK_SUCCESS_METRIC_NAME,
      );
      logInfo('Investigation has been successfully initiated for the seller');
      MarioUtil.submitPage(landingPageWidget.current);
    } else {
      if (response.httpStatusCode === 500) {
        publishCounterMonitorMetric(
          PMET_METHOD_NAME,
          PMET_INITIATE_INVESTIGATION_500_STATUS_CODE_COUNT,
        );
        throw new Error(
          `Error occurred while calling InitiateInvestigation API with status code: ${response.httpStatusCode}`,
        );
      }
      publishCounterMonitorMetric(
        PMET_METHOD_NAME,
        PMET_INITIATE_INVESTIGATION_BUTTON_CLICK_VALIDATION_EXCEPTION_METRIC_NAME,
      );
      logError(
        `InitiateInvestigation API failed with status code ${response.httpStatusCode}`,
      );
      setShouldDisplayErrorMessage(true);
    }
  };

  const invokeInitiateInvestigationAPI = (
    startTime: number,
    retryCount: number,
  ) => {
    const initiateInvestigationResponse = MarioAjaxHandler.fetchPost(
      INITIATE_INVESTIGATION,
      INITIATE_INVESTIGATION_API_TIMEOUT,
    );
    initiateInvestigationResponse
      .then((response: AjaxResponse) => {
        processInitiateInvestigationResponse(response, startTime);
      })
      .catch((ex: Error) => {
        if (ex.name === 'AbortError') {
          logError(
            `Call to InitiateInvestigation API timed out with remaining retries ${retryCount}`,
          );
          publishTimerMonitorMetric(
            PMET_METHOD_NAME,
            PMET_INITIATE_INVESTIGATION_API_TIMEOUT_COUNT,
            startTime,
          );
          publishCounterMonitorMetric(
            PMET_METHOD_NAME,
            PMET_INITIATE_INVESTIGATION_API_ABORT_ERROR_COUNT,
          );
        }

        if (retryCount < 1) {
          publishTimerMonitorMetric(
            PMET_METHOD_NAME,
            PMET_INITIATE_INVESTIGATION_BUTTON_CLICK_LATENCY_METRIC_NAME,
            startTime,
          );
          publishCounterMonitorMetric(
            PMET_METHOD_NAME,
            PMET_INITIATE_INVESTIGATION_BUTTON_CLICK_FAILURE_METRIC_NAME,
          );
          logError(
            `Call to InitiateInvestigation API failed with no retries left and with exception ${ex}`,
          );
          setShouldDisplayErrorMessage(true);
        } else {
          publishCounterMonitorMetric(
            PMET_METHOD_NAME,
            PMET_INITIATE_INVESTIGATION_API_RETRY_COUNT,
          );
          logError(
            `Call to InitiateInvestigation API failed with remaining retries ${retryCount} and with exception ${ex}`,
          );
          invokeInitiateInvestigationAPI(startTime, retryCount - 1);
        }
      });
  };

  const initiateInvestigation = () => {
    logInfo(
      'Seller has clicked the `Continue to document upload` button to proceed to the Document Upload Page',
    );
    const startTime = new Date().getTime();
    publishCounterMonitorMetric(
      PMET_METHOD_NAME,
      PMET_INITIATE_INVESTIGATION_BUTTON_CLICK_METRIC_NAME,
    );

    invokeInitiateInvestigationAPI(startTime, DEFAULT_API_RETRY_COUNT);
  };

  logInfo(`Rendering LandingPageComponent UI elements`);
  publishCounterMonitorMetric(PMET_METHOD_NAME, PMET_RENDER_METRIC_NAME);
  return (
    <div id="landing-page" ref={landingPageWidget}>
      <h1 className="title-padding">
        {bundle.getMessage('landing-page-title-text')}
      </h1>
      <h4 className="subtitle-padding">
        {bundle.getMessage('landing-page-body')}
      </h4>
      <KatButton
        id="landingPageContinueButton"
        className="button-padding"
        variant="primary"
        label={bundle.getMessage('landing-page-button')}
        onClick={() => initiateInvestigation()}
      />
      <KatAlert
        id="landingPageErrorAlert"
        description={bundle.getMessage('landing-page-error-banner-message')}
        variant="danger"
        dismissed={!shouldDisplayErrorMessage}
        onDismiss={() => setShouldDisplayErrorMessage(false)}
      />
    </div>
  );
};

export const LandingPage = withBundle('pages.ipilandingPage')(
  LandingPageComponent,
);
