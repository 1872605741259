import { ClassElement, Decorator } from './decorators';

export interface FormInputMapConfig {
  /**
   * The form input tag to be created. E.G. input, select, etc. Can be anything really.
   * The created tag will be passed on to `setup` to post-process based on component's
   * business requirements and current state.
   * Required.
   */
  tag: string;

  /**
   * The value of the name attribute of the created form tag.
   * The created tag will be passed on to `setup` to post-process based on component's
   * business requirements and current state.
   * Required.
   */
  name: string | ((component: HTMLElement) => string);

  /**
   * Specify the conditions that indicate a form input is needed.
   * The form input will be created if and only if this resolves to true.
   * Required.
   */
  isNeeded: boolean | ((component: HTMLElement) => boolean);

  /**
   * Function to set up the required parameters and attributes
   * on the form input tag
   * Required.
   */
  setup: (component: HTMLElement, formInput: HTMLElement) => void;
}

const getPrivateLightDom = (element: HTMLElement): HTMLSpanElement => {
  let span: HTMLElement = element.querySelector(
    "span[slot='private-light-dom']"
  );
  if (!span) {
    span = document.createElement('span');
    span.setAttribute('slot', 'private-light-dom');
    span.style.maxWidth = '0px';
    span.style.maxHeight = '0px';
    span.style.overflow = 'hidden';
    element.appendChild(span);
  }
  return span;
};

const isNeeded = (
  component: HTMLElement,
  mapping: FormInputMapConfig
): boolean =>
  typeof mapping.isNeeded === 'function'
    ? mapping.isNeeded(component)
    : mapping.isNeeded;

const getName = (component: HTMLElement, mapping: FormInputMapConfig): string =>
  typeof mapping.name === 'function' ? mapping.name(component) : mapping.name;

const createFormInputElement = (
  component: HTMLElement,
  mapping: FormInputMapConfig,
  spanDom: HTMLSpanElement
) => {
  const input: HTMLElement = document.createElement(mapping.tag);
  input.setAttribute('hidden', 'true');
  input.setAttribute('name', getName(component, mapping));
  spanDom.appendChild(input);

  return input;
};

const setupFormInputMappings = (
  component: HTMLElement,
  formInputMapConfigs: FormInputMapConfig[]
) => {
  const spanDom = getPrivateLightDom(component);
  spanDom.innerHTML = '';

  formInputMapConfigs.forEach(mapping => {
    isNeeded(component, mapping) &&
      mapping.setup(
        component,
        createFormInputElement(component, mapping, spanDom)
      );
  });
};

/**
 * A decorator that can be placed on `updated` lifecycle method to perform
 * component mapping onto form inputs.
 *
 * @decorator
 * @param formInputMapConfigs The array of configurations to describe what needs to be mapped to form inputs.
 */
export const formInputMap =
  (formInputMapConfigs: FormInputMapConfig[]): Decorator =>
  (proto: ClassElement) => {
    const fn = proto.descriptor.value;

    function doMapping(...args) {
      fn.apply(this, args);
      setupFormInputMappings(this, formInputMapConfigs);
    }

    proto.descriptor.value = doMapping;
    return proto;
  };
