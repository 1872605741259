import { initialMetricsPublisher } from 'src/metrics';

export const publishCounterMonitorMetric = (
  methodName: string,
  metric: string,
) => {
  const actionMetricsPublisher =
    initialMetricsPublisher.newChildActionPublisherForMethod(methodName);
  actionMetricsPublisher.publishTimerMonitor(metric, 1);
};

export const publishTimerMonitorMetric = (
  methodName: string,
  metric: string,
  startTime: number,
) => {
  const endTime = new Date().getTime();
  const duration = endTime - startTime;
  const actionMetricsPublisher =
    initialMetricsPublisher.newChildActionPublisherForMethod(methodName);
  actionMetricsPublisher.publishTimerMonitor(metric, duration);
};
