/**
 * Traditionally, Panther expects translations to be hosted at a separate endpoint that can be retrieved at runtime.
 * https://w.amazon.com/bin/view/I18N-Eng/Products/AmazonResourceBundle/JS_UserGuide/
 *
 * Instead, using `bb convert-puffj-to-ast`, we can convert PUFF-J files into Amazon String Translator style single files
 * that can be bundled at build time and exported with the component.
 */
interface PantherPlural {
  param: string;
  pluralItems?: {
    one?: string;
    few?: string;
    many?: string;
    other?: string;
  };
}
type Translations = Record<
  string,
  Record<string, string> | Record<string, PantherPlural>
>;

export const getLocale = () =>
  // Detect locale from html tag
  // If not specified use browser locale settings from navigator.language
  // If browser locale was not specified either, fall back to en-US
  document.querySelector('html').getAttribute('lang') ??
  // https://caniuse.com/?search=navigator - safe to suppress this eslint error
  // eslint-disable-next-line compat/compat
  navigator.language ??
  'en-US';

/**
 * Takes into account user specified locale override.
 * If none is specified, detects the locale from HTML tag -> browser navigator
 * and returns the translation in the detected locale.
 *
 * If no translation is found in any of the specified locales,
 * returns string in en-US as the last resort.
 *
 * For Panther-originated strings with plurals, a `pluralOptions` object must be passed with the desired quantity and
 * a list of two-value arrays for which param to target, and what to replace it with.
 * e.g. replacer: [['0', 'Zero'], ['1', 'ten']] would change the string
 * "{0,number,integer} items selected out of {1,number,integer}." to "Zero items selected out of ten."
 *
 * If after all attempts en-US does not exist either, returns undefined
 */
export const katTranslator =
  <T extends Translations>(strings: T) =>
  (
    stringId: keyof T,
    options?: Record<string, string>,
    locale?: string,
    pluralOptions?: {
      quantity: 'one' | 'few' | 'many' | 'other';
      replacers: [string, string][];
    }
  ) => {
    let translation;

    if (pluralOptions) {
      // Retrieve string key and it's locale, or default to en-US
      const stringLocale = (strings[stringId][locale ?? getLocale()] ??
        strings[stringId]['en-US']) as PantherPlural;
      translation = stringLocale.pluralItems[pluralOptions.quantity];
      // If chosen plural quantity is not available, fallback to 'other'
      translation = translation
        ? translation
        : stringLocale.pluralItems['other'];
      // Replace `{<param>, <type>, <descriptor>}` fields with a defined value
      pluralOptions.replacers.forEach(
        replacer =>
          (translation = translation.replace(
            new RegExp(`{${replacer[0]}.*?}`),
            replacer[1]
          ))
      );
    } else {
      translation = strings[stringId][locale ?? getLocale()];
      translation = translation ?? strings[stringId]['en-US'];
    }

    if (options) {
      Object.keys(options).forEach(key => {
        const value = options[key];
        translation = translation?.replace(
          new RegExp('{' + key + '}', 'g'),
          value
        );
      });
    }

    return translation;
  };
