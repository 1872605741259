import { html, property } from 'lit-element';
import { nothing } from 'lit-html';
import {
  KatLitElement,
  Keys,
  register,
  event,
  EventEmitter,
} from '../../shared/base';
import { checkSlots } from '../../shared/slot-utils';

import baseStyles from '../../shared/base/base.lit.scss';
import styles from './modal.lit.scss';
import getString from './strings';

/**
 * @component {kat-modal} KatalModal Modals are intentionally disruptive windows that surface one task or piece of information. They sit on top of the page contents, blocking user access and interaction with all other page elements.
 * @slot default Elements without a named slot attribute appear inside the main modal body.
 * @slot title Title element appears inside the modal header. Takes precedence over title property.
 * @slot footer Contents will be used as the modal footer. Add 'kat-group-horizontal' on a footer element to automatically space its children.
 * @example Basic {"visible": false, "content": "<span slot=\"title\">Modal Title</span><p>Lorem ipsum dolor sit amet, sit nihil utamur ne, alia persequeris sit id, usu ut erant quando. Primis deleniti scribentur sit ex.</p><p>The buttons in this example will not perform any actions. It is up the the application's logic to decide what happens when buttons are added to a Modal</p><div slot=\"footer\" class=\"kat-group-horizontal\"><kat-button variant=\"secondary\" label=\"Cancel\"></kat-button><kat-button label=\"Submit\"></kat-button></div>"}
 * @example noCloseIcon {"visible":false, "no-close-icon": true, "title": "Modal With No Close Icon", "content":"<p>Lorem ipsum dolor sit amet, sit nihil utamur ne, alia persequeris sit id, usu ut erant quando. Primis deleniti scribentur sit ex.</p><p>The buttons in this example will not perform any actions. It is up the the application's logic to decide what happens when buttons are added to a Modal</p><div slot=\"footer\" class=\"kat-group-horizontal\"><kat-button variant=\"secondary\" label=\"Cancel\"></kat-button><kat-button label=\"Submit\"></kat-button></div>"}
 * @example ScrollFixed { "visible": false, "content":"<span slot=\"title\">Scroll Modal Body</span><p>The buttons in this example will not perform any actions. It is up the the application's logic to decide what happens when buttons are added to a Modal</p><kat-box variant=\"white-junglemist\" ><p>Modal has a fixed header and footer... keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>You've reached the end!</p></kat-box><div slot=\"footer\" class=\"kat-group-horizontal\"><kat-button variant=\"secondary\" label=\"Cancel\"></kat-button><kat-button label=\"Submit\"></kat-button></div>"}
 * @example ScrollAll { "visible": false, "scroll-all": true, "content":"<span slot=\"title\">Scroll Entire Modal</span><p>The buttons in this example will not perform any actions. It is up the the application's logic to decide what happens when buttons are added to a Modal</p><kat-box variant=\"white-junglemist\" ><p>Modal scrolls along with the page... keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>keep scrolling</p><p>You've reached the end!</p></kat-box><div slot=\"footer\" class=\"kat-group-horizontal\"><kat-button variant=\"secondary\" label=\"Cancel\"></kat-button><kat-button label=\"Submit\"></kat-button></div>"}
 * @status Production
 * @theme flo
 * @guideline Do Modals can work effectively when the information being requested or presented is relevant or can streamline the completion of the current task.
 * @guideline Dont Don't use modal dialogs for nonessential information that is not related to the current user flow.
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-modal')
export class KatModal extends KatLitElement {
  /** Determines if the dialog is open or closed. */
  @property()
  visible?: boolean;

  /**
   * Title text that will be displayed to the user. NOTE: title slot takes precedence over title property
   * @required false
   */
  @property()
  title: string;

  /** Determines whether to show or hide the header's close icon */
  @property({ attribute: 'no-close-icon' })
  noCloseIcon?: boolean;

  /** Scroll the entirety of the modal along the page (no fixed header + footer). Defaults to false */
  @property({ attribute: 'scroll-all' })
  scrollAll?: boolean;

  /** Prevent the modal from being closed when a non-modal element is clicked. When set to true, hide's the header's close icon. Defaults to false. */
  @property()
  persistent? = false;

  /** Fires when the modal dialog is opened. */
  @event('open', { cancelable: true })
  private _open: EventEmitter<{ visible: boolean }>;

  /** Fires when the modal dialog closes. */
  @event('close', { cancelable: true })
  private _close: EventEmitter<{ visible: boolean }>;

  static get styles() {
    return [baseStyles, styles];
  }

  private _clickStartedOnModal: boolean;
  private _dialogTarget: Element;

  constructor() {
    super();
    this._clickStartedOnModal = false;
  }

  _handleKeyPressEvent(event) {
    if (!!this.visible && event.keyCode === Keys.Escape && !this.persistent) {
      this.close();
    }
  }

  _handleMouseDown(event) {
    this._clickStartedOnModal = event
      .composedPath()
      .includes(this._dialogTarget);
  }

  _handleMouseUp(event) {
    if (
      !this._clickStartedOnModal &&
      !event.composedPath().includes(this._dialogTarget) &&
      !this.persistent
    ) {
      this.close();
    }

    this._clickStartedOnModal = false;
  }

  /**
   * Call to close the modal. Fires the close event.
   *
   * @katalmethod
   */
  async close() {
    const canceled = !this._close.emit({ visible: false });

    if (canceled) {
      return;
    }

    this.visible = false;
    await this.updateComplete;
  }

  /**
   * Call to open the modal. Fires the open event.
   *
   * @katalmethod
   */
  async open() {
    const canceled = !this._open.emit({ visible: true });

    if (canceled) {
      return;
    }

    this.visible = true;
    await this.updateComplete;
  }

  _childrenChanged() {
    this.requestUpdate();
  }

  firstUpdated() {
    this.setAttribute('role', 'dialog');
    this.setAttribute('aria-modal', 'true');

    this._dialogTarget = this.shadowRoot.querySelector('.dialog');
    document.addEventListener('keydown', e => this._handleKeyPressEvent(e), {
      capture: true,
      passive: true,
    });
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    document.removeEventListener('keydown', this._handleKeyPressEvent, true);
  }

  updated(changed) {
    if (changed.has('visible')) {
      document.body.classList.toggle('kat-no-scroll', !!this.visible);
    }

    if (changed.has('title') || checkSlots(this)?.title) {
      this.setAttribute('aria-labelledby', 'title');
      this.removeAttribute('aria-label');
    } else {
      this.removeAttribute('aria-labelledby');
      this.setAttribute('aria-label', getString('kat-modal-dialog-label'));
    }
  }

  render() {
    const result = checkSlots(this);
    const header = this.title || result.title || !this.noCloseIcon;
    return html`
      <div
        class="container"
        @mousedown=${this._handleMouseDown}
        @mouseup=${this._handleMouseUp}
      >
        <div class="dialog">
          ${header
            ? html`
                <div class="header" part="modal-header">
                  <div class="title" id="title">
                    <slot name="title" @slotchange=${this._childrenChanged}
                      >${this.title
                        ? html`
                            <span class="title__inner">${this.title}</span>
                          `
                        : nothing}</slot
                    >
                  </div>
                  ${this.noCloseIcon || this.persistent
                    ? nothing
                    : html`
                        <button
                          class="close"
                          type="button"
                          @click=${this.close}
                          part="modal-close-button"
                          aria-label="${getString('kat-modal-close')}"
                        >
                          <kat-icon
                            class="close__icon"
                            part="modal-close-icon"
                            name="exit"
                          ></kat-icon>
                        </button>
                      `}
                </div>
              `
            : nothing}
          <div class="body">
            <slot></slot>
          </div>
          <div class="footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    `;
  }
}
