/**
 * Looks through the given `el`'s light dom and figures out what slots are being
 * used. Returns an object where the key is the slot name & the value is `true`.
 * If a slot isn't filled, then it won't be in the returned object. Any
 * non-empty text nodes or elements without a `slot` attribute will cause the
 * returned object to have a `default: true` property.
 */
export function checkSlots(el: HTMLElement) {
  const nodes = Array.from(el.childNodes);

  return nodes.reduce<Record<string, boolean>>((memo, node) => {
    const slot = getNodeSlot(node);
    if (slot) {
      memo[slot] = true;
    }
    return memo;
  }, {});
}

/**
 * Looks through the given `el`'s light dom and figures out what slots are being
 * used. Returns an object where the key is the slot name & the value is the slot node.
 * If a slot isn't filled, then it won't be in the returned object. Any
 * non-empty text nodes or elements without a `slot` attribute will cause the
 * returned object to have a `default: node` property.
 */
export function getSlotValues(el: HTMLElement, allowlist?: string[]) {
  const nodes = Array.from(el.childNodes);

  return nodes.reduce((memo, node) => {
    const slot = getNodeSlot(node);
    if (slot && (!allowlist || allowlist.includes(slot))) {
      memo[slot] = node.cloneNode(true);
    }
    return memo;
  }, {});
}

/**
 * Determine the slot that the given node is assigned to. If the element is not
 * slottable (comments, empty text nodes), then null is returned. Otherwise, the
 * slot name is returned.
 */
export function getNodeSlot(node: Node) {
  switch (node.nodeType) {
    case 3 /* TEXT_NODE */: {
      // text node only containing whitespace
      if (isWhitespace((node as Text).data)) {
        return null;
      }

      return 'default';
    }
    case 1 /* ELEMENT_NODE */: {
      const slot = (node as HTMLElement).slot;
      return slot || 'default';
    }
  }

  return null;
}

/**
 * Remove whitespace from default slot if it only contains text nodes with only whitespace
 */
export function cleanDefaultSlot(component: HTMLElement) {
  Array.from(component.childNodes).forEach(child => {
    if (
      child.nodeType === 3 /* TEXT_NODE */ &&
      isWhitespace(child.textContent)
    ) {
      component.removeChild(child);
    }
  });
}

function isWhitespace(str: string) {
  return /^\s*$/.test(str);
}
