import { logInfo } from 'src/utils/logger/LoggerUtil';
import {
  AjaxGet,
  AjaxPost,
  AjaxResponse,
  createAjaxResponse,
} from '../../../src/utils/ajax/AjaxHandler';

const createURL = (
  relativeUrl: string,
  queryParams?: Record<string, string>,
): string => {
  if (!queryParams) {
    return relativeUrl;
  }
  const queryParamString = new URLSearchParams(queryParams);
  return `${relativeUrl}?${queryParamString}`;
};

const buildGetHeaders = (): Record<string, string> => ({
  'Content-Type': 'application/json',
});

const getCSRFToken = () => {
  const csrfToken = document.querySelector("meta[name='anti-csrftoken-a2z']");
  return !csrfToken ? '' : csrfToken.getAttribute('content')!;
};

const buildPostHeaders = (): Record<string, string> => ({
  'Content-Type': 'application/json',
  'anti-csrftoken-a2z': getCSRFToken(),
});

const buildPostFileUploadHeaders = (): Record<string, string> => ({
  'anti-csrftoken-a2z': getCSRFToken(),
});

export const MarioAjaxHandler: AjaxGet & AjaxPost = {
  get: async (
    requestUrl: string,
    timeout: number,
    queryParams?: Record<string, string>,
  ): Promise<AjaxResponse> => {
    const relativeURI = createURL(requestUrl, queryParams);
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const requestParams: RequestInit = {
      method: 'GET',
      headers: buildGetHeaders(),
      signal: controller.signal,
    };

    logInfo(
      `Invoking fetch GET with URI ${relativeURI} and ` +
        `request parameters ${JSON.stringify(requestParams)}`,
    );
    const response = await window.fetch(relativeURI, requestParams);
    clearTimeout(id);

    return createAjaxResponse(response);
  },

  fetchPost: async (
    requestUrl: string,
    timeout: number,
    data: any,
    queryParams?: Record<string, string>,
  ): Promise<AjaxResponse> => {
    const relativeURI = createURL(requestUrl, queryParams);
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const requestParams = {
      method: 'POST',
      headers: buildPostHeaders(),
      body: data,
      signal: controller.signal,
    };

    logInfo(
      `Invoking fetch POST with URI ${relativeURI}, ` +
        `request parameters ${JSON.stringify(requestParams)} and ` +
        `timeout ${timeout}`,
    );

    const response = await window.fetch(relativeURI, requestParams);
    clearTimeout(id);

    return createAjaxResponse(response);
  },

  postFileUpload: async (
    requestUrl: string,
    timeout: number,
    file: File,
    queryParams?: Record<string, string>,
  ): Promise<AjaxResponse> => {
    const relativeURI = createURL(requestUrl, queryParams);
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);

    const requestParams = {
      method: 'POST',
      headers: buildPostFileUploadHeaders(),
      body: file,
      signal: controller.signal,
    };

    logInfo(
      `Invoking fetch PostFileUpload with URI ${relativeURI}, ` +
        `request parameters ${JSON.stringify(requestParams)} and ` +
        `timeout ${timeout}`,
    );

    const response = await window.fetch(relativeURI, requestParams);
    clearTimeout(id);

    return createAjaxResponse(response);
  },
};
