import React from 'react';
import ReactDOM from 'react-dom';
import { KatSpinner } from '@amzn/katal-react';
import { PantherI18nWrapper } from 'src/components/PantherI18nWrapper';
import { App } from './components/App';
import { AppInitWrapper } from './components/AppInitWrapper';
import 'src/utils/logger/LoggerUtil';

import '@amzn/katal-components/styles.css';
import './stylesheets/ipi/document-upload-page.scss';
import './stylesheets/ipi/document-upload-unit.scss';
import './stylesheets/ipi/common.scss';
import './stylesheets/ipi/error-page.scss';

// The Suspense component causes React to display the fallback KatSpinner until
// the i18n translation strings have been loaded.

ReactDOM.render(
  <React.StrictMode>
    <AppInitWrapper>
      <React.Suspense fallback={<KatSpinner size="large" />}>
        <PantherI18nWrapper>
          <App />
        </PantherI18nWrapper>
      </React.Suspense>
    </AppInitWrapper>
  </React.StrictMode>,
  document.getElementById('root'),
);
