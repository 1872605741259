import { katTranslator } from '../../shared/i18n';

const strings = {
  'kat-modal-close': {
    'en-US': 'close',
    'ar-AE': 'إغلاق',
    'bn-IN': 'বন্ধ করুন',
    'de-DE': 'schließen',
    'es-ES': 'cerrar',
    'es-MX': 'cerrar',
    'fr-BE': 'fermer',
    'fr-FR': 'fermer',
    'gu-IN': 'બંધ કરો',
    'hi-IN': 'बंद करें',
    'it-IT': 'chiudi',
    'ja-JP': '閉じる',
    'kn-IN': 'ಮುಚ್ಚಿ',
    'ko-KR': '닫기',
    'ml-IN': 'അടയ്‌ക്കുക',
    'mr-IN': 'बंद करा',
    'nl-BE': 'sluiten',
    'nl-NL': 'sluiten',
    'pl-PL': 'zamknij',
    'pt-BR': 'fechar',
    'pt-PT': 'fechar',
    'sv-SE': 'stäng',
    'ta-IN': 'மூடு',
    'te-IN': 'మూసివేయి',
    'th-TH': 'ปิด',
    'tr-TR': 'kapat',
    'vi-VN': 'đóng',
    'zh-CN': '关闭',
    'zh-TW': '關閉',
  },
  'kat-modal-dialog-label': {
    'en-US': 'Feedback form',
    'ar-AE': 'نموذج التعليقات',
    'bn-IN': 'ফিডব্যাক ফর্ম',
    'de-DE': 'Feedback-Formular',
    'es-ES': 'Formulario de valoraciones',
    'es-MX': 'Formulario de valoración',
    'fr-BE': 'Formulaire des commentaires',
    'fr-FR': "Formulaire d'évaluation",
    'gu-IN': 'ફીડબેક ફોર્મ',
    'hi-IN': 'फ़ीडबैक फ़ॉर्म',
    'it-IT': 'Modulo feedback',
    'ja-JP': 'フィードバックフォーム',
    'kn-IN': 'ಫೀಡ್‌ಬ್ಯಾಕ್ ಫಾರ್ಮ್',
    'ko-KR': '피드백 양식',
    'ml-IN': 'ഫീഡ്ബാക്ക് ഫോം',
    'mr-IN': 'फीडबॅक फॉर्म',
    'nl-BE': 'Feedbackformulier',
    'nl-NL': 'Feedbackformulier',
    'pl-PL': 'Formularz opinii o sprzedawcy',
    'pt-BR': 'Formulário de comentário',
    'pt-PT': 'Formulário de comentários',
    'sv-SE': 'Feedbackformulär',
    'ta-IN': 'ஃபீட்பேக் ஃபார்ம்',
    'te-IN': 'ఫీడ్‌బ్యాక్ ఫారమ్',
    'th-TH': 'แบบฟอร์มความคิดเห็น',
    'tr-TR': 'Değerlendirme formu',
    'vi-VN': 'Biểu mẫu phản hồi',
    'zh-CN': '反馈表',
    'zh-TW': '意見回饋表單',
  },
};

export default katTranslator(strings);
