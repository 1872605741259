import { html, property } from 'lit-element';
import { nothing } from 'lit-html';
import { KatLitElement, register } from '../../shared/base';
import { checkSlots } from '../../shared/slot-utils';
import baseStyles from '../../shared/base/base.lit.scss';
import cardStyles from './card.lit.scss';

/**
 * @component {kat-card} KatalCard Cards display information about a single subject and either allow the user to take immediate action or act as an entry point to more detailed information. Cards are flexible and can accept a wide variety of content.<br /> <strong>Note</strong>: Seller Central home page cards do not use the Katal card component.
 * @status Production
 * @theme katal
 * @example Basic {
 * "content": "
 *  <span slot=\"title\">
 *      Title
 *  </span>
 * <kat-button variant=\"link\" label=\"Action\" slot=\"action\">
 *  </kat-button>
 * <span slot=\"subtitle\">
 *      Subtitle
 *  </span>
 *   <div>
 *      Body content
 *  </div>
 * <div slot=\"footer\">
 *      Footer
 *  </div>
 * "}
 *  @example TitleProperty {
 * "title": "Title as Property",
 * "content": "
 * <kat-button variant=\"link\" label=\"Action\"  slot=\"action\">
 *  </kat-button>
 * <span slot=\"subtitle\">
 *      Subtitle
 *  </span>
 *   <div>
 *      Body content
 *  </div>
 * <div slot=\"footer\">
 *      Footer
 *  </div>
 * "}
 *  @example Warning {
 * "variant": "warning",
 * "content": "
 * <span slot=\"title\">Warning</span>
 * <span slot=\"subtitle\">
 *      Subtitle
 *  </span>
 *   <div>
 *      Body content
 *  </div>
 * "}
 *  @example Danger {
 * "variant": "danger",
 * "content": "
 * <span slot=\"title\">Danger</span>
 * <span slot=\"subtitle\">
 *      Subtitle
 *  </span>
 *   <div>
 *      Body content
 *  </div>
 * "}
 * @guideline Do Use Cards when there is a small area in which a large or varying amount of information must be communicated
 * @guideline Do Use simple, succinctly-messaged and clearly-designed information that can be understood quickly
 * @guideline Do Keep the number of cards shown to a manageable amount that does not require scrolling on desktop. Too many can be overwhelming.
 * @guideline Dont Don't use a card if the information requires more room to be  communicated clearly, or takes several steps to complete
 * @guideline Dont Don't overload a card with information or messaging, rendering it difficult to understand
 * @slot default Elements without a named slot attribute; appear in the card body
 * @slot title Title element; takes precedence over title property
 * @slot action Action element
 * @slot subtitle Subtitle element
 * @slot footer Footer element
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */
@register('kat-card')
export class KatCard extends KatLitElement {
  /**
   * Title text that will be displayed to the user. NOTE: title slot takes precedence over title property
   * @required false
   */
  @property({ reflect: false })
  title: string;

  /** Setting to true will remove the default padding in the body and/or default slot */
  @property({ attribute: 'no-body-padding' })
  noBodyPadding = false;

  /**
   * Stateful card variant (similar to alert). Attribute is not present by default, but it can explicitly be set to string "default"
   * @enum {value} default No variant (plain card). Property isn't present by default, but it can explicitly be set to string "default"
   * @enum {value} warning Signals moderate issues that may not require immediate attention
   * @enum {value} danger Signals critical issues that likely require immediate attention
   */
  @property()
  variant: string = null;

  static get styles() {
    return [baseStyles, cardStyles];
  }

  firstUpdated() {
    this.observeChildren(() => this.requestUpdate());
  }

  render() {
    const slots = checkSlots(this);
    const hasTitle = this.title || slots.title;
    const hasHeader = hasTitle || slots.action;

    const renderedTitle = hasTitle
      ? html`<div class="title">
          <slot name="title">${this.title || nothing}</slot>
        </div>`
      : nothing;

    const renderedAction = slots.action
      ? html`<div class="action"><slot name="action"></slot></div>`
      : nothing;

    const renderedHeader = hasHeader
      ? html`<div class="header">${renderedTitle}${renderedAction}</div>`
      : nothing;

    const renderedSubtitle = slots.subtitle
      ? html`<div class="subtitle"><slot name="subtitle"></slot></div>`
      : nothing;

    const renderedBody = slots.default
      ? html`<div class="body"><slot></slot></div>`
      : nothing;

    const renderedFooter = slots.footer
      ? html`<div class="footer">
          <slot name="footer"></slot>
        </div>`
      : nothing;

    return html`
      ${renderedHeader} ${renderedSubtitle} ${renderedBody} ${renderedFooter}
    `;
  }
}
