import { katTranslator } from '../../shared/i18n';

const strings = {
  kat_progress_label: {
    'en-US': 'Progress',
    'ar-AE': 'التقدم',
    'bn-IN': 'অগ্রগতি',
    'de-DE': 'Fortschritt',
    'es-ES': 'Progreso',
    'es-MX': 'En curso',
    'fr-BE': 'Avancement',
    'fr-FR': 'En cours',
    'gu-IN': 'પ્રોગ્રેસ',
    'hi-IN': 'प्रोग्रेस',
    'it-IT': 'Avanzamento',
    'ja-JP': '進行状況',
    'kn-IN': 'ಪ್ರಗತಿ',
    'ko-KR': '진행 상황',
    'ml-IN': 'പുരോഗതി',
    'mr-IN': 'प्रगती',
    'nl-BE': 'Voortgang',
    'nl-NL': 'Voortgang',
    'pl-PL': 'Postęp',
    'pt-BR': 'Progresso',
    'pt-PT': 'Progresso',
    'sv-SE': 'Pågående',
    'ta-IN': 'முன்னேற்றம்',
    'te-IN': 'ప్రోగ్రెస్',
    'th-TH': 'ความคืบหน้าของการดำเนินการ',
    'tr-TR': 'İlerleme',
    'vi-VN': 'Đang tiến hành',
    'zh-CN': '进度',
    'zh-TW': '進度',
  },
};

export default katTranslator(strings);
