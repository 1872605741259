import { logInfo } from 'src/utils/logger/LoggerUtil';

export enum MarioEvent {
  REGISTER = 'register',
  SUBMIT = 'submit',
  SUBMIT_ATTEMPTED = 'submit-attempted',
  SUBMIT_DATA = 'submit-data',
  SUBMIT_ABORT = 'submit-abort',
  SUBMIT_FAILED_DATA = 'submit-failed-data',
}

const MarioUtilHelper = {
  /**
   * Registers the Widget Element in Mario by dispatching a Mario Register Event
   *
   * @param widgetElement - root element of widget
   */
  registerWidget(widgetElement: HTMLElement): void {
    const registerEvent = new CustomEvent(MarioEvent.REGISTER, {
      detail: {
        dispatcherNode: widgetElement,
      },
      bubbles: true,
    });
    logInfo(`Dispatching Mario register event on widget ${widgetElement.id}`);
    widgetElement.dispatchEvent(registerEvent);
  },

  /**
   * Dispatches a Mario page submit event to submit the page.
   *
   * @param widgetElement - root element of widget
   */
  submitPage(widgetElement: HTMLElement): void {
    const submitPageEvent = new CustomEvent(MarioEvent.SUBMIT, {
      detail: {},
      bubbles: true,
    });
    logInfo(`Dispatching Mario submit event on widget ${widgetElement.id}`);
    widgetElement.dispatchEvent(submitPageEvent);
  },

  /**
   * Add event listener to 'submit-attempted' Mario event to make a call to the callback function
   *
   * @param widgetElement - root element of widget
   * @param callback - callback function to be called when submit page is triggered
   */
  addSubmitAttemptedListener(
    widgetElement: HTMLElement,
    callback: EventListener,
  ): void {
    logInfo(`Adding event listener to Mario submit-attempted event`);
    widgetElement.addEventListener(MarioEvent.SUBMIT_ATTEMPTED, callback);
  },

  /**
   * Remove 'submit-attempted' Mario event listener
   *
   * @param widgetElement - root element of widget
   * @param callback - callback function to be called when submit page is triggered
   */
  removeSubmitAttemptedListener(
    widgetElement: HTMLElement,
    callback: EventListener,
  ): void {
    logInfo(`Removing event listener to Mario submit-attempted event`);
    widgetElement.removeEventListener(MarioEvent.SUBMIT_ATTEMPTED, callback);
  },

  /**
   * Dispatch 'submit-data' event to Mario along with data that needs to be sent to backend
   *
   * @param widgetElement - root element of widget
   * @param widgetData - data to be passed to Mario library for submit
   */
  submitWidgetData(widgetElement: HTMLElement, widgetData: any): void {
    const submitWidgetEvent = new CustomEvent(MarioEvent.SUBMIT_DATA, {
      detail: {
        data: widgetData,
      },
      bubbles: true,
    });
    logInfo(
      `Dispatching Mario submit-data event on widget ${widgetElement.id} and data ${widgetData}`,
    );
    widgetElement.dispatchEvent(submitWidgetEvent);
  },

  /**
   * Dispatch 'submit-abort' event to Mario to abort submit operation to backend
   *
   * @param widgetElement - root element of widget
   */
  abortSubmit(widgetElement: HTMLElement): void {
    const widgetSubmitEvent = new CustomEvent(MarioEvent.SUBMIT_ABORT, {
      bubbles: true,
    });
    logInfo(
      `Dispatching Mario submit-abort event on widget ${widgetElement.id}`,
    );
    widgetElement.dispatchEvent(widgetSubmitEvent);
  },

  /**
   * Add event listener to 'submit-failed-data' Mario event to make a call to the callback function
   *
   * @param widgetElement - root element of widget
   * @param callback - callback function to be called when submit-failed-data is triggered by Mario
   */
  addSubmitFailedDataListener(
    widgetElement: HTMLElement,
    callback: EventListener,
  ): void {
    logInfo(`Adding event listener to Mario submit-failed-data event`);
    widgetElement.addEventListener(MarioEvent.SUBMIT_FAILED_DATA, (event) =>
      callback(event),
    );
  },

  /**
   * Remove event listener to 'submit-failed-data' Mario event
   *
   * @param widgetElement - root element of widget
   * @param callback - callback function to be called when submit-failed-data is triggered by Mario
   */
  removeSubmitFailedDataListener(
    widgetElement: HTMLElement,
    callback: EventListener,
  ): void {
    logInfo(`Removing event listener to Mario submit-failed-data event`);
    widgetElement.removeEventListener(MarioEvent.SUBMIT_FAILED_DATA, callback);
  },
};

export const MarioUtil = MarioUtilHelper;
