interface AjaxSuccessResponse {
  ok: true;
  data: any;
}

interface AjaxFailureResponse {
  ok: false;
  httpStatusCode: number;
  data: any;
}

export type AjaxResponse = AjaxSuccessResponse | AjaxFailureResponse;

export interface AjaxGet {
  get: (
    requestUrl: string,
    timeout: number,
    queryParams?: Record<string, string>,
  ) => Promise<AjaxResponse>;
}

export interface AjaxPost {
  fetchPost: (
    requestUrl: string,
    timeout: number,
    data?: any,
    queryParams?: Record<string, string>,
  ) => Promise<AjaxResponse>;

  postFileUpload: (
    requestUrl: string,
    timeout: number,
    data?: any,
    queryParams?: Record<string, string>,
  ) => Promise<AjaxResponse>;
}

export interface AjaxPut {
  put: (
    requestUrl: string,
    data: any,
    queryParams?: Record<string, string>,
  ) => Promise<AjaxResponse>;
}

const createSuccessResponse = (value: any): AjaxSuccessResponse => ({
  ok: true,
  data: value,
});

const createFailureResponse = (
  httpStatusCode: number,
  data: any,
): AjaxFailureResponse => ({
  ok: false,
  data,
  httpStatusCode,
});

export const createAjaxResponse = (response: Response): Promise<AjaxResponse> =>
  response
    .json()
    .then((data) =>
      response.ok
        ? createSuccessResponse(data)
        : createFailureResponse(response.status, data),
    );
