import { html, css } from 'lit-element';
import { KatLitElement, register } from '../../shared/base';
import { getFirstMatchingParent } from '../../shared/utils';

/**
 * @component {kat-popover-hide} KatalPopoverHide A simple wrapper element that binds a click event and closes the popover.
 */
@register('kat-popover-hide')
export class KatPopoverHide extends KatLitElement {
  static get styles() {
    return css`
      :host {
        cursor: pointer;
      }
    `;
  }

  constructor() {
    super();
    this._listeners = [['click', this._handleClick]];
  }

  _handleClick() {
    getFirstMatchingParent(this, el => el.tagName === 'KAT-POPOVER')?.hide();
  }

  render() {
    return html`<slot></slot>`;
  }
}
