import { html, property } from 'lit-element';
import { nothing } from 'lit-html';
import { classMap } from 'lit-html/directives/class-map';
import {
  event,
  EventEmitter,
  KatLitElement,
  register,
} from '../../shared/base';

import baseStyles from '../../shared/base/base.lit.scss';
import { KatFileUploadStatus, KatFileUploadView } from './file-upload-defs';
import styles from './file-item.lit.scss';

/**
 * @component {kat-file-item} KatalFileItem Private. Used to render an attached file in list mode in KatalFileUpload component.
 * @status hidden
 * @theme flo
 */

@register('kat-file-item')
export class KatFileItem extends KatLitElement {
  /**
   * The name of the file this file item represents
   * @type {string}
   */
  @property()
  name: string;

  /**
   * Set to true to disable UI interaction with file upload component.
   * This flag has no effect on programmatic interaction.
   * @type {boolean}
   */
  @property()
  disabled?: boolean;

  /**
   * Indicates the progression percent of the upload process. Valid range 0 - 100.
   * All other values will be treated as indeterminate progress
   */
  @property()
  percent?: number = -1;

  /**
   * The state of the file this file item represents.
   * One of KatFileUploadState.READY - literal value 'ready', KatFileUploadState.UPLOADING - literal value 'uploading',
   * KatFileUploadState.COMPLETE - literal value 'complete', KatFileUploadState.ERROR - literal value 'error'.
   * Defaults to KatFileUploadState.READY.
   * @enum {value} ready The file is attached and ready to be uploaded.
   * @enum {value} uploading The attached file is currently uploading.
   * @enum {value} complete The attached file is successfully uploaded.
   * @enum {value} error The file upload has failed.
   */
  @property({
    attribute: 'file-status',
    reflect: true,
    converter: {
      fromAttribute: value =>
        KatFileUploadStatus[value?.toUpperCase()] || KatFileUploadStatus.READY,
      toAttribute: value => value,
    },
  })
  fileStatus: KatFileUploadStatus = KatFileUploadStatus.READY;

  /**
   * Rendering mode of the attached file(s) list.
   * @enum {value} list Renders this file as a list item.
   * @enum {value} list-preview Renders this file as a list item with attachment preview.
   * @enum {value} grid Renders this file as a grid tile with attachment preview.
   */
  @property({
    attribute: 'file-view',
    reflect: true,
    converter: {
      fromAttribute: value =>
        KatFileUploadView[value?.toUpperCase().replace('-', '_')] ||
        KatFileUploadView.LIST,
      toAttribute: value => value,
    },
  })
  fileView?: KatFileUploadView = KatFileUploadView.LIST;

  /**
   * Fires when user clicks on the trash/X button to remove this attachment.
   */
  @event('fileRemoved', false)
  private _fileRemoved: EventEmitter<{ name: string }>;

  static get styles() {
    return [baseStyles, styles];
  }

  private _onFileItemDelete = event => {
    event.preventDefault();

    this._fileRemoved.emit({ name: this.name });
  };

  /**
   * Call this at @slotchange on a slot tag
   * @private
   */
  private _childrenChanged() {
    this.requestUpdate();
  }

  private get _fileItemClasses() {
    return {
      itemClasses: {
        error: this.fileStatus === KatFileUploadStatus.ERROR,
        grid: this.fileView === KatFileUploadView.GRID,
      },
      iconClasses: {
        error: this.fileStatus === KatFileUploadStatus.ERROR,
        hidden: this.fileStatus === KatFileUploadStatus.READY,
      },
      thumbClasses: {
        hidden: this.fileView === KatFileUploadView.LIST,
      },
      errorClasses: {
        hidden: this.fileStatus !== KatFileUploadStatus.ERROR,
      },
      titleClasses: {
        ['no-icon']: this.fileStatus === KatFileUploadStatus.READY,
      },
    };
  }

  private get _hasProgress() {
    return !isNaN(this.percent) && this.percent >= 0 && this.percent <= 100;
  }

  private get _statusIcon() {
    switch (this.fileStatus) {
      case KatFileUploadStatus.ERROR:
        return html` <kat-icon name="error" size="small"></kat-icon> `;
      case KatFileUploadStatus.UPLOADING:
        return html`
          <kat-progress
            type="circular"
            size="small"
            ?indeterminate=${!this._hasProgress}
            value=${this.percent}
          ></kat-progress>
        `;
      case KatFileUploadStatus.COMPLETE:
        return html` <kat-icon name="check_circle" size="small"></kat-icon> `;
      default:
        return nothing;
    }
  }

  private get _trashIconName() {
    switch (this.fileStatus) {
      case KatFileUploadStatus.READY:
      case KatFileUploadStatus.UPLOADING:
        return 'close';
      case KatFileUploadStatus.COMPLETE:
      case KatFileUploadStatus.ERROR:
      default:
        return 'trash';
    }
  }

  private get _trashButton() {
    return html`
      <button
        class="file-upload-file-item-trash"
        @click=${this._onFileItemDelete}
      >
        <kat-icon name=${this._trashIconName} size="small"></kat-icon>
      </button>
    `;
  }

  renderBottomContainer() {
    const { iconClasses, errorClasses, titleClasses } = this._fileItemClasses;
    return html` <div
        class="file-upload-file-item-icon ${classMap(iconClasses)}"
      >
        ${this._statusIcon}
      </div>
      <div class="file-upload-file-item-title ${classMap(titleClasses)}">
        <div class="file-upload-file-item-name">${this.name}</div>
        <div class="file-upload-file-item-error ${classMap(errorClasses)}">
          <kat-popover
            kat-aria-behavior="tooltip"
            position="bottom"
            trigger-type="click"
          >
            <div slot="trigger" class="file-upload-file-item-error-summary">
              <slot
                name="error-summary"
                @slotchange=${this._childrenChanged}
              ></slot>
            </div>
            <div class="file-upload-file-item-error-detail">
              <slot
                name="error-detail"
                @slotchange=${this._childrenChanged}
              ></slot>
            </div>
          </kat-popover>
        </div>
      </div>
      ${this._trashButton}
    </div>`;
  }

  render() {
    const { itemClasses, thumbClasses } = this._fileItemClasses;

    return html`
      <div class="file-upload-file-item ${classMap(itemClasses)}">
        <div class="file-upload-file-item-thumbnail ${classMap(thumbClasses)}">
          <slot name="thumbnail" @slotchange=${this._childrenChanged}></slot>
        </div>
        ${itemClasses.grid
          ? html`
              <div class="bottom_grid_container">
                ${this.renderBottomContainer()}
              </div>
            `
          : this.renderBottomContainer()}
      </div>
    `;
  }
}
