import { Keys } from '../shared/base';

/**
 * Check if the given event is either a click, Enter, or Spacebar.
 * @param e The event to check.
 */
export function isActionableEvent(e: Event): boolean {
  if (e.type === 'click') {
    return true;
  }

  if (e.type === 'keydown') {
    if (Keys.Confirm.includes((e as KeyboardEvent).keyCode)) {
      return true;
    }
  }

  return false;
}

/**
 * Check if the given {@link KeyboardEvent} is tab next (shift key not pressed).
 * @param e The {@link KeyboardEvent} to inspect.
 * @returns True or False
 */
export function isTabNext(e: KeyboardEvent): boolean {
  return e.keyCode === Keys.Tab && !e.shiftKey;
}

/**
 * Check if the given {@link KeyboardEvent} is tab previous (shift key is pressed).
 * @param e The {@link KeyboardEvent} to inspect.
 * @returns True or False
 */
export function isTabPrevious(e: KeyboardEvent): boolean {
  return e.keyCode === Keys.Tab && e.shiftKey;
}

type EventArgs = [string, (e: any) => void, AddEventListenerOptions?];

export interface EventManager {
  /** Add all event listeners to the target. */
  on(target: EventTarget): void;
  /** Remove all event listeners from the target. */
  off(target: EventTarget): void;
  /** Add or remove event listeners based on a boolean. */
  set(target: EventTarget, val: boolean): void;
}

/**
 * Create an {@link EventManager} object which can be used to easily enable and
 * disable an event listener on an element.
 *
 * @param events An array of {@link EventArgs} objects which will all be added/removed
 *     when calling the on/off methods.
 */
export function createEventManager(events: EventArgs[]): EventManager {
  const targets = new Set<any>();

  return {
    on(target) {
      if (!targets.has(target)) {
        targets.add(target);
        events.forEach(e => target.addEventListener(...e));
      }
    },

    off(target) {
      if (targets.has(target)) {
        events.forEach(e => target.removeEventListener(...e));
        targets.delete(target);
      }
    },

    set(target, val) {
      if (val) {
        this.on(target);
      } else {
        this.off(target);
      }
    },
  };
}
