import { LocalizationContextBuilder } from '@amzn/arb-tools';

export const DOMAIN: Record<string, string> = {
  beta: 'test',
  gamma: 'test',
  prod: 'prod',
};
export const DEFAULT_DOMAIN = 'test';
export const REALM: Record<string, string> = {
  'eu-west-1': 'EUAmazon',
  'us-east-1': 'USAmazon',
  'us-west-2': 'JPAmazon',
};
export const DEFAULT_REALM = 'USAmazon';
export const DEFAULT_LOCALE = 'en-US';
export const DEFAULT_LC_BUILDER =
  new LocalizationContextBuilder().withDefaultLocale(DEFAULT_LOCALE);
export const MARIO_IPI_WEBPAGE_PREFIX =
  '/mario/ipi/IPISellerVerificationService/global/node';
export const GET_LATEST_DOCUMENTS_METADATA_URL =
  '/mario/ipi/latestdocumentsdata';
export const MAX_FILE_UPLOAD_SIZE_IN_BYTES = 4194304; // 4 MB
export const ACCEPTED_FILE_EXTENSIONS = ['.jpg', '.jpeg', '.pdf', '.png'];
export const ACCEPTED_FILE_UPLOAD_FORMATS = ACCEPTED_FILE_EXTENSIONS.join(',');
export const INITIATE_IPV = '/mario/ipi/initiateIpv';
export const INITIATE_INVESTIGATION = '/mario/ipi/initiateinvestigation';
export const UPLOAD_DOCUMENT = '/mario/ipi/uploadDocument';
export const DEFAULT_API_RETRY_COUNT = 3;
export const INITIATE_IPV_API_TIMEOUT = 7500;
export const INITIATE_INVESTIGATION_API_TIMEOUT = 5000;
export const GET_LATEST_DOCUMENTS_METADATA_API_TIMEOUT = 5000;
export const UPLOAD_DOCUMENT_API_TIMEOUT = 25000;
export const MARIO_IPI_LANDING_PAGE_RELATIVE_URL =
  '/mario/ipi/IPISellerVerificationService/global/node/verificationRequired/render';
// TODO: Replace with actual link
export const SELLER_SUPPORT_CONTACT_RELATIVE_URL = '/cu/contact-us';
export const HTTPS_PROTOCOL = 'https://';
