import { html, property } from 'lit-element';
import { KatLitElement, register } from '../../shared/base';
import { checkSlots } from '../../shared/slot-utils';

import baseStyles from '../../shared/base/base.lit.scss';
import styles from './progress.lit.scss';
import getString from './strings';

/**
 * @component {kat-progress} KatalProgress The progress component shows users visual feedback on the status or progress of an operation, such as a file upload or processing of data. This component replaces the <a href="/components/progressbar/">Progressbar</a> and <a href="/components/spinner/">Spinner</a> components.
 * @guideline Do Use the appropriate type (circular or linear) and size based on the space within your design.
 * @guideline Do Use determinate mode when there is a finite, definable amount of work to be done.
 * @guideline Do Use indeterminate mode when the progress for an activity can not be determined, or is likely to take less than 250ms.
 * @guideline Do Include a label unless it is immediately and obviously clear what is happening from the surrounding context.
 * @guideline Do If some processing must occur before you are able to provide a progress estimate, you may transition between indeterminate and determinate modes.
 * @guideline Do Use a single Progress component as a loader in most cases, regardless of how much content you are loading. Only use multiple Progress components if you are waiting on multiple services or events, and expect a wide variance in response time.
 * @guideline Dont Don't mix linear and circular Progress components.
 * @guideline Dont Don't transition between linear and circular.
 * @guideline Dont Don't use for step-based experiences, such as registration or forms. Use the Workflow Tracker istead.
 * @status Production
 * @theme flo
 * @slot label Contents will be used as the label of the progress.
 * @example LinearSmallDeterminateWorking {"value":50,"size":"small"}
 * @example LinearDeterminateWorking {"value":50}
 * @example LinearLargeDeterminateWorking {"value":50,"size":"large"}
 * @example LinearSmallDeterminateWarning {"value":50,"size":"small","state":"warning"}
 * @example LinearWithLabel {"value":50,"content":"<div slot=\"label\">This is the label</div>"}
 * @example LinearIndeterminateWorking {"indeterminate":true}
 * @example CircularSmallDeterminateWorking {"value":50,"size":"small","type":"circular"}
 * @example CircularDeterminateWorking {"value":50,"type":"circular"}
 * @example CircularLargeDeterminateWorking {"value":50,"size":"large","type":"circular"}
 * @example CircularSmallDeterminateWarning {"value":50,"size":"small","state":"warning","type":"circular"}
 * @example CircularWithLabel {"value":50,"type":"circular","content":"<div slot=\"label\">This is the label</div>"}
 * @example CircularIndeterminateWorking {"indeterminate":true,"type":"circular"}
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */

@register('kat-progress')
export class KatProgress extends KatLitElement {
  /**
   * The starting value of a determinate progress.
   * Defaults to 0.
   * It is possible and valid that start value is greater than the end value, in which case the progress range is
   * considered reversed. Consider this the case of work remaining rather than work completed.
   * Ignored if progress is indeterminate.
   * @type {number}
   */
  @property({ attribute: 'start-value' })
  startValue?: number;

  /**
   * The ending value of a determinate progress.
   * Defaults to startValue + 100. Typical use case is that either start and end values are both specified,
   * or they are both left to their defaults, in which case endValue would result to 100.
   * It is possible and valid that end value is lesser than the start value, in which case the progress range is
   * considered reversed. Consider this the case of work remaining rather than work completed.
   * Ignored if progress is indeterminate.
   * @type {number}
   */
  @property({ attribute: 'end-value' })
  endValue?: number;

  /**
   * The current progression value in-between startValue and endValue.
   * Defaults to startValue.
   * @type {number}
   */
  @property()
  value?: number;

  /**
   * The type of the progress. Defaults to linear.
   * @enum {value} linear Renders linear (horizontal) progress bar.
   * @enum {value} circular Renders circular (spinner) progress.
   */
  @property()
  type: 'circular' | 'linear' = 'linear';

  /**
   * The size (the thickness of the line or the radial size of the circular type) of the progress. Defaults to medium.
   * @enum {value} small For circular progress the width/height of the spinner is 20px x 20px,
   *                     for linear progress the thickness of the progress line is 4px.
   * @enum {value} medium For circular progress the width/height of the spinner is 40px x 40px,
   *                      for linear progress the thickness of the progress line is 10px.
   * @enum {value} large For circular progress the width/height of the spinner is 80px x 80px,
   *                     for linear progress the thickness of the progress line is 14px.
   */
  @property()
  size: 'small' | 'medium' | 'large' = 'medium';

  /**
   * The state of the progress. Defaults to working.
   * @enum {value} working Indicates that the process is in progression. The rendering colors are the default.
   * @enum {value} warning Indicates the process has had some warnings. The rendering colors are that of a warning.
   * @enum {value} error Indicates the process has errored. The rendering colors are that of an error.
   * @enum {value} success Indicates the process has succeeded. The rendering colors are that of a success.
   */
  @property()
  state: 'working' | 'warning' | 'error' | 'success' = 'working';

  /**
   * Specifies whether the process is indeterminate and the progress should cycle.
   */
  @property()
  indeterminate = false;

  /**
   * Specifies whether the progress percentage value should be hidden for determinate process.
   */
  @property({ attribute: 'hide-value' })
  hideValue = false;

  /**
   * Label read by assistive technology.
   */
  @property({ attribute: 'kat-aria-label' })
  katAriaLabel?: string;

  /**
   * Locale override for katal provided string translations.
   */
  @property()
  locale?: string;

  static get styles() {
    return [baseStyles, styles];
  }

  private _svgBox = 20;

  private _getStartValue(): number {
    return !isNaN(this.startValue) ? this.startValue : 0;
  }

  private _getEndValue(): number {
    return !isNaN(this.endValue) ? this.endValue : this._getStartValue() + 100;
  }

  private _getValue(): number {
    const start = this._getStartValue();
    const end = this._getEndValue();
    const val = !isNaN(this.value) ? this.value : start;

    // make sure that the user-specified value is sanitized and does not under/over-shoot the specified range
    return this._isRangeReversed
      ? val > start
        ? start
        : val < end
        ? end
        : val
      : val < start
      ? start
      : val > end
      ? end
      : val;
  }

  private get _isRangeReversed(): boolean {
    return this._getStartValue() > this._getEndValue();
  }

  private get _cssIndeterminate(): string {
    return this.indeterminate ? 'indeterminate' : '';
  }

  private get _cssProgression(): string {
    return this.indeterminate ? '' : `width:${this.progression}%`;
  }

  private get _isWorking(): boolean {
    return this.state === 'working';
  }

  private get _cssValue(): string {
    return this.indeterminate || !this._isWorking || this.hideValue
      ? 'hidden'
      : '';
  }

  private get _cssIcon(): string {
    return !this._isWorking ? '' : 'hidden';
  }

  private get _cssLabel(): string {
    const slots = checkSlots(this);
    return slots.label ? '' : 'hidden';
  }

  private get _iconName(): string {
    switch (this.state) {
      case 'error':
        return 'error';
      case 'warning':
        return 'warning';
      case 'success':
        return 'checkmark-circle-fill';
      default:
        return '';
    }
  }

  private _childrenChanged() {
    this.requestUpdate();
  }

  private get _size(): string {
    return this.size === 'small' || this.size === 'large'
      ? this.size
      : 'medium';
  }

  private get _unroundedProgression(): number {
    const start = this._getStartValue();
    const end = this._getEndValue();
    const val = this._getValue();

    // return normalized progress position in percents.
    // The formula guarantees a value between 0 and 100
    return this._isRangeReversed
      ? ((start - val) / (start - end)) * 100
      : ((val - start) / (end - start)) * 100;
  }

  private get _circularPath(): string {
    let degree = 3.6 * this._unroundedProgression;

    // do not allow 360 (100%) be treated as 0 (0%)
    degree = degree > 359.5 ? 359.5 : degree;

    const rad = ((degree - 90) * Math.PI) / 180;
    const size = (this._svgBox - 2) >> 1;
    const largeArc = degree > 180 ? 1 : 0;
    const edgeOffset = 1;

    const x = edgeOffset + size * (1 + Math.cos(rad));
    const y = edgeOffset + size * (1 + Math.sin(rad));

    return `M${
      size + edgeOffset
    } ${edgeOffset} A${size} ${size} 0 ${largeArc} 1 ${x} ${y}`;
  }

  private get _circularSVG() {
    const csize = (this._svgBox - 2) >> 1;
    const bsize = this._svgBox >> 1;
    return this.indeterminate
      ? html`
          <svg
            viewBox="0 0 ${this._svgBox} ${this._svgBox}"
            class="indeterminate"
          >
            <path
              d="M${csize} 1 A${csize} ${csize} 0 1 1 1 ${csize}"
              stroke-linecap="round"
              class="meter"
            />
          </svg>
        `
      : html`
          <svg viewBox="0 0 ${this._svgBox} ${this._svgBox}">
            <circle cx="${bsize}" cy="${bsize}" r="${csize}" class="track" />
            <path
              d="${this._circularPath}"
              stroke-linecap="round"
              class="meter"
            />
          </svg>
        `;
  }

  private get _ariaLabel() {
    if (!this.katAriaLabel) {
      return getString('kat_progress_label', null, this.locale);
    }

    return this.katAriaLabel;
  }

  /**
   * progress.value will return the user specified value
   * progress.progression will return the amount of work done translated within the range of [0..100]
   */
  get progression(): number {
    return Math.round(this._unroundedProgression);
  }

  private _renderLinear() {
    return html`
      <div part="linear-wrapper" class="linear-wrapper">
        <div part="linear-label" class="linear-label ${this._cssLabel}">
          <slot name="label" @slotchange=${this._childrenChanged}></slot>
        </div>
        <div part="linear-bar-and-val" class="linear-bar-and-val">
          <div part="linear-bar" class="linear-bar">
            <div
              part="linear-meter"
              class="linear-meter ${this._cssIndeterminate}"
              style="${this._cssProgression}"
            ></div>
          </div>
          <div part="linear-val" class="linear-val ${this._cssValue}">
            ${this.progression}%
          </div>
          <div part="linear-icon" class="linear-icon ${this._cssIcon}">
            <kat-icon
              part="icon"
              name="${this._iconName}"
              size="small"
            ></kat-icon>
          </div>
        </div>
      </div>
    `;
  }

  private _renderCircular() {
    return html`
      <div class="circular-wrapper ${this._size}">
        <div part="circular-svg-icon-wrapper" class="circular-svg-icon-wrapper">
          <div class="circular-svg-wrapper ${this.state}">
            ${this._circularSVG}
          </div>
          <div class="circular-icon ${this._cssIcon}">
            <kat-icon
              part="icon"
              name="${this._iconName}"
              size="small"
            ></kat-icon>
          </div>
          <div part="circular-val" class="circular-val ${this._cssValue}">
            ${this.progression}%
          </div>
        </div>
        <div part="circular-label" class="circular-label ${this._cssLabel}">
          <slot name="label" @slotchange=${this._childrenChanged}></slot>
        </div>
      </div>
    `;
  }

  render() {
    return this.indeterminate
      ? html`
          <div
            class="progress"
            aria-live="polite"
            role="progressbar"
            aria-label="${this._ariaLabel}"
          >
            ${this.type === 'circular'
              ? this._renderCircular()
              : this._renderLinear()}
          </div>
        `
      : html`
          <div
            class="progress"
            aria-live="polite"
            role="progressbar"
            aria-valuenow="${this.progression}"
            aria-label="${this._ariaLabel}"
          >
            ${this.type === 'circular'
              ? this._renderCircular()
              : this._renderLinear()}
          </div>
        `;
  }
}
