import KatalLogger, { Level } from '@amzn/katal-logger';
import { v4 as uuidV4 } from 'uuid';

const katalLoggerConfig = {
  url: '/mons/ipi/app-logger',
  logThreshold: Level.INFO,
  maxLogLineSize: 10000,
  logToConsole: false,
};
const LOG_SESSION_ID_FIELD = 'logSessionId';
const logger = new KatalLogger(katalLoggerConfig);
logger.addErrorListener();

// Fetches session ID from the browser cookie and creates a new one if not found
const getOrCreateLogSessionId = (): string => {
  const match = document.cookie.match(
    `(^|;)\\s*${LOG_SESSION_ID_FIELD}\\s*=\\s*([^;]+)`,
  );
  if (match) {
    return match.pop()!;
  }
  const newLogSessionId = uuidV4();
  void logger.info(
    `Log sessions ID missing. Generating a new log session id and setting it within the cookie.`,
  );
  document.cookie = `${LOG_SESSION_ID_FIELD}=${newLogSessionId}; path=/`;
  return newLogSessionId;
};

// Log INFO level with log session ID
export const logInfo = (message: string) => {
  void logger.info(`[SessionId-${getOrCreateLogSessionId()}] ${message}`);
};

// Log WARN level with log session ID
export const logWarn = (message: string) => {
  void logger.warn(`[SessionId-${getOrCreateLogSessionId()}] ${message}`);
};

// Log ERROR level with log session ID
export const logError = (message: string, error?: Error) => {
  void logger.error(
    `[SessionId-${getOrCreateLogSessionId()}] ${message}`,
    error,
  );
};
