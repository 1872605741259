// For keyCode of Key events.
// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
//@deprecated - use keyboard constants instead
export const Keys = {
  Backspace: 8,
  Enter: 13,
  Space: 32,
  Escape: 27,
  Tab: 9,

  ArrowLeft: 37,
  ArrowUp: 38,
  ArrowRight: 39,
  ArrowDown: 40,

  PageUp: 33,
  PageDown: 34,
  End: 35,
  Home: 36,

  One: 49,
  Two: 50,
  Three: 51,
  Four: 52,
  Five: 53,
  Six: 54,
  Seven: 55,
  Eight: 56,
  Nine: 57,

  // special combinations

  // Enter or Space
  Confirm: [13, 32],

  // ArrowLeft or ArrowUp
  Previous: [37, 38],

  // ArrowRight or ArrowDown
  Next: [39, 40],
};
