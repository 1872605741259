const ANNOUNCE_TIMEOUT = 2000;

/**
 * Create a function that sets the innerText of the given element, and after a
 * timeout, clears the text. The given element should have the attribute
 * `aria-live="polite"` so that it will announce dynamic changes to screen
 * readers.
 * @see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/ARIA_Live_Regions
 * @param el The element to write text to.
 * @return A function that can be used to announce changes.
 */
export function createAnnouncer(el: HTMLElement) {
  let timeout = null;

  return (msg: string) => {
    el.innerText = msg;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      el.innerText = '';
    }, ANNOUNCE_TIMEOUT);
  };
}
