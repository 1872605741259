export enum KatFileUploadVariant {
  SMALL = 'small',
  LARGE = 'large',
}

export enum KatFileUploadView {
  LIST = 'list',
  LIST_PREVIEW = 'list-preview',
  GRID = 'grid',
}

export enum KatFileUploadStatus {
  READY = 'ready',
  UPLOADING = 'uploading',
  COMPLETE = 'complete',
  ERROR = 'error',
}

export interface KatFileUploadState {
  status: KatFileUploadStatus;
  error?: string;
}

export interface KatFileItemUploadState {
  file: File;
  status: KatFileUploadStatus;
  percent?: number;
  preview?: string;
  errorSummary?: string;
  errorDetail?: string;
}

export interface KatFileItemUpdateState {
  name: string;
  status: KatFileUploadStatus;
  percent?: number;
  preview?: string;
  errorSummary?: string;
  errorDetail?: string;
}
