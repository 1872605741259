import { html, css, property } from 'lit-element';
import { Keys, KatLitElement, register } from '../../shared/base';
import { getFirstMatchingParent } from '../../shared/utils';
import { createEventManager, EventManager } from '../../utils/event-utils';
import { KatPopover } from './popover';

const TRIGGER_TOGGLE = 'toggle';
const TRIGGER_DISPLAY = 'display';
const TRIGGER_HIDE = 'hide';

/**
 * @component {kat-popover-trigger} KatalPopoverTrigger Used to mark some content as a trigger and anchor for an associated popover.
 * It should only be used as a remote trigger, to trigger a popover placed somewhere else on the page. It must be given a "for" attribute,
 * otherwise it won't have any behavior. It should not be placed inside of a "kat-popover" element.
 */
@register('kat-popover-trigger')
export class KatPopoverTrigger extends KatLitElement {
  /**
   * Determines how a user triggers the associated popover to show and hide when interacting with this trigger.
   * Defaults to click.
   * @enum {value} click When clicked and the popover is hidden the popover will be shown and vice-versa.
   * @enum {value} focus When an element inside the trigger is focused the popover appears. If the element is blurred the popover closes.
   * @enum {value} hover Show the popover when an element inside the trigger is hovered. If the trigger is clicked, the popover will remain open until it loses focus. Otherwise, it will close when no longer hovered.
   */
  @property()
  type = 'click';

  /**
   * Determines where the popover will be anchored to this trigger. The popover will anchor the side specified and centered
   * Defaults to "bottom". If the popover would be clipped by the window at the specified position it will automatically move to another adjacent
   * position that isn't clipped.
   * @enum {value} top Positions the popover above the trigger.
   * @enum {value} right Positions the popover to the right of the trigger.
   * @enum {value} bottom Positions the popover below the trigger.
   * @enum {value} left Positions the popover to the left of the trigger.
   */
  @property()
  position = 'bottom';

  /** The id of a popover on the page for this trigger to be associated with. The popover will be anchored to this trigger. */
  @property()
  for: string;

  static get styles() {
    return css`
      :host {
        display: inline-block;
      }
    `;
  }

  _eventManager: EventManager;

  _currentPopover = null;
  _currentControl = null;
  _currentContent = null;

  _popoverFocused = false;
  _persistPopover = false;
  _wasKeyboardInit = false;
  _popoverHovered = false;
  _triggerHovered = false;

  constructor() {
    super();

    this.observeChildren(() => this.requestUpdate());

    this._eventManager = createEventManager([
      ['focusin', this.onFocusPopover],
      ['focusout', this.onBlurPopover],
      ['mousedown', this.onClickPopover],
      ['keyup', this.onKeyPressPopover],
      ['mouseenter', this.onHoverPopover],
      ['mouseleave', this.onHoverOutPopover],
    ]);
  }

  firstUpdated() {
    this.addEventListener(
      'keydown',
      e => {
        if (Keys.Confirm.includes(e.keyCode)) {
          this._wasKeyboardInit = true;
        }
      },
      true
    );

    this.createTriggerHandle(
      'click',
      'click',
      TRIGGER_TOGGLE,
      (evt, popover) => {
        // Clicks can be triggered by keyboard events as well but we only want to
        // do the below logic if it's a keyboard event.
        if (!this._wasKeyboardInit) return;

        this._wasKeyboardInit = false;

        if (popover.katAriaBehavior === 'interactive') {
          setTimeout(() => {
            popover._content.focus();
          });
        }
      }
    );

    this.createTriggerHandle('focus', 'focusin', TRIGGER_DISPLAY, () => {
      this._popoverFocused = true;
      this._persistPopover = false;
    });

    this.createTriggerHandle(
      'focus',
      'focusout',
      TRIGGER_HIDE,
      (e, popover) =>
        popover.visible && !this._popoverFocused && !this._persistPopover
    );

    this.createTriggerHandle(
      'hover',
      ['mouseenter', 'focusin'],
      TRIGGER_DISPLAY,
      () => {
        this._persistPopover = false;
        this._triggerHovered = true;
      }
    );

    this.createTriggerHandle('hover', 'click', TRIGGER_TOGGLE, (e, popover) => {
      if (popover.visible && !this._persistPopover) {
        this.onClickPopover();
        return false;
      }
    });

    // createTriggerHandle does not support async callbacks.
    ['mouseleave', 'focusout'].forEach(event => {
      this.addEventListener(event, () => {
        this._triggerHovered = false;
        this.hoverOutBehaviour();
      });
    });
  }

  /**
   * Creates an event handle used to display or hide the associated popover.
   * @param {string} type Should be one of the possible values of the type property.
   * @param {string|Array} eventName Should be a standard event that can bubble up (e.g. "focus" and "blur" don't bubble).
   * @param {string} behaviour One of "toggle", "display", or "hide" that determine how this event affects the popover.
   *  - "toggle" means that each time the event fires the popover can transition from visible to not or vice-versa.
   *  - "display" means to be just displayed if not visible.
   *  - "hide" means it will be hidden if visible.
   * @param {function} behaviorHandlingCb This callback is invoked before the desired behaviour is attempted.
   *     Return `false` to prevent the default behavior.
   */
  createTriggerHandle(type, eventName, behavior, behaviorHandlingCb) {
    const events = Array.isArray(eventName) ? eventName : [eventName];

    events.forEach(event => {
      this.addEventListener(event, e => {
        if (this.type !== type) return;

        const popover = this.getPopover();
        if (!popover) return;

        // At runtime, we allow the registrant to opt-out of the desired behaviour.
        if (behaviorHandlingCb && behaviorHandlingCb(e, popover) === false) {
          return;
        }

        // If lastTrigger is this trigger then it's okay to hide otherwise this
        // popover is being shared so it should be moved to this trigger
        if (popover.visible && popover._currentTrigger === this) {
          if (behavior === TRIGGER_HIDE || behavior === TRIGGER_TOGGLE) {
            popover.hide();
            this.updateAriaWhenPopoverToggled();
          }
        } else {
          if (behavior === TRIGGER_DISPLAY || behavior === TRIGGER_TOGGLE) {
            this.displayPopover(popover);
          }
        }
      });
    });
  }

  /**
   * Returns the popover associated with this trigger. Selects the closest parent.
   */
  getPopover(): KatPopover {
    if (this.for) {
      const popover = document.getElementById(this.for);
      if (popover?.tagName === 'KAT-POPOVER') {
        return popover as KatPopover;
      }
    }

    return getFirstMatchingParent(
      this,
      e => e.tagName === 'KAT-POPOVER'
    ) as KatPopover;
  }

  displayPopover(popover) {
    popover.show(this);

    // Attempt to hide all other popovers
    Array.from(document.getElementsByTagName('kat-popover')).forEach(other => {
      if (other !== popover) {
        other.hide();
      }
    });

    this.updateAriaWhenPopoverToggled();

    if (this._currentPopover === popover) return;

    // Remove events from last popover, necessary since events from that popover
    // could cause undefined behaviour
    const lastContent = this._currentPopover?._content;
    if (lastContent) {
      this._eventManager.off(lastContent);
    }

    // Register this popover as the current popover and add events.
    this._currentPopover = popover;

    const content = popover._content;
    if (content) {
      this._eventManager.on(content);
    }
  }

  onHoverPopover = () => {
    this._popoverHovered = true;
  };

  onHoverOutPopover = () => {
    this._popoverHovered = false;
    this.hoverOutBehaviour();
  };

  hoverOutBehaviour = () => {
    if (this.type !== 'hover') return;

    if (!this._persistPopover) {
      setTimeout(() => {
        const popover = this.getPopover();
        if (
          popover?.visible &&
          !this._popoverHovered &&
          !this._popoverFocused &&
          !this._triggerHovered
        ) {
          this._persistPopover = false;
          popover.hide();
          this.updateAriaWhenPopoverToggled();
        }
      }, 200);
    }
  };

  hideIfNotFocused() {
    if (this.type !== 'focus' && this.type !== 'hover') return;

    this._popoverFocused = false;
    setTimeout(() => {
      if (!this._popoverFocused && !this._persistPopover) {
        const popover = this.getPopover();
        if (popover?.visible) {
          popover.hide();
          this.updateAriaWhenPopoverToggled();
        }
      }
    });
  }

  onFocusPopover = () => {
    this._popoverFocused = true;
  };

  onBlurPopover = () => {
    this.hideIfNotFocused();
  };

  onClickPopover = () => {
    this._persistPopover = true;
  };

  onKeyPressPopover = evt => {
    if (evt.keyCode === Keys.Escape) {
      const popover = this.getPopover();
      if (popover) {
        popover.hide();
        this.updateAriaWhenPopoverToggled();
      }
    }
  };

  /**
   * Used to update the aria attributes when the related popover changes its aria behavior.
   */
  updateAriaAttributes() {
    this.requestUpdate();
  }

  updateAriaWhenPopoverToggled() {
    const popover = this.getPopover();
    const target = this.children[0];
    if (!popover || !target) return;

    if (popover.katAriaBehavior === 'interactive') {
      target.setAttribute('aria-expanded', popover.visible);
    }
  }

  render() {
    const slot = html`<slot></slot>`;

    const popover = this.getPopover();
    const control = this.children[0];

    // only update aria for distant triggers - popover handles inner triggers
    if (!control || !popover || !this.for) {
      return slot;
    }

    // Do nothing if the aria relevant elements haven't changed
    if (
      this._currentControl === control &&
      this._lastBehavior === popover.katAriaBehavior
    ) {
      return slot;
    }

    // If the control changed remove the associated ids
    if (
      this._currentControl &&
      (this._currentControl !== control ||
        this._lastBehavior !== popover.katAriaBehavior)
    ) {
      this._currentControl.removeAttribute('aria-controls');
      this._currentControl.removeAttribute('aria-expanded');
      this._currentControl.removeAttribute('aria-flowto');
      this._currentControl.removeAttribute('aria-describedby');
    }

    this._lastBehavior = popover.katAriaBehavior;
    this._lastControl = control;

    // Best practices as laid out by this site: https://baseweb.design/components/popover/#popover-opens-on-hover
    const popoverId = popover._contentId;
    if (popover.katAriaBehavior === 'interactive') {
      // Interactive treats the popover like a panel with interactive elements
      control.setAttribute('aria-controls', popoverId);
      control.setAttribute('aria-expanded', !!popover.visible);
      // When the popover is distant use aria-flowto to allow the user to move to the popover content
      control.setAttribute('aria-flowto', popoverId);
    } else if (popover.katAriaBehavior === 'tooltip') {
      // Tooltip treats the popover as a tooltip with simple content
      control.setAttribute('aria-describedby', popoverId);
    }

    return slot;
  }
}
