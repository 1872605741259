import React, { useEffect, useRef } from 'react';
import { KatAlert, KatButton, KatCard, KatSpinner } from '@amzn/katal-react';
import { v4 as uuidv4 } from 'uuid';
import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import { DocumentUploadUnit } from 'src/components/ipi/DocumentUploadUnit';
import {
  GET_LATEST_DOCUMENTS_METADATA_URL,
  INITIATE_IPV,
  UPLOAD_DOCUMENT,
  DEFAULT_API_RETRY_COUNT,
  INITIATE_IPV_API_TIMEOUT,
  GET_LATEST_DOCUMENTS_METADATA_API_TIMEOUT,
  UPLOAD_DOCUMENT_API_TIMEOUT,
} from 'src/constants';
import { AjaxGet, AjaxPost, AjaxResponse } from 'src/utils/ajax/AjaxHandler';
import { GetLatestDocumentsMetadataResponse } from 'src/model/GetLatestDocumentsMetadataResponse';
import { DocumentType } from 'src/model/DocumentType';
import { DocumentMetadata } from 'src/model/DocumentMetadata';
import { FileMetadata } from 'src/model/FileMetadata';
import { MarioUtil } from 'src/utils/MarioUtil';
import { logError, logInfo } from 'src/utils/logger/LoggerUtil';
import {
  publishCounterMonitorMetric,
  publishTimerMonitorMetric,
} from 'src/utils/MetricUtil';
import {
  areFileExtensionsSupported,
  isAnyFileSizeZero,
} from 'src/utils/FileUtil';

export const DocumentUploadPageComponent = ({
  get,
  fetchPost,
  postFileUpload,
  bundle,
}: AjaxGet & AjaxPost & WithBundleProps) => {
  logInfo(`Loading DocumentUploadPageComponent`);
  const documentUploadPageWidget = useRef<any>(null);
  const [identityDocumentMetadata, setIdentityDocumentMetadata] =
    React.useState<DocumentMetadata | undefined>(undefined);
  const [bankStatementDocumentMetadata, setBankStatementDocumentMetadata] =
    React.useState<DocumentMetadata | undefined>(undefined);
  const [
    businessRegistrationDocumentMetadata,
    setBusinessRegistrationDocumentMetadata,
  ] = React.useState<DocumentMetadata | undefined>(undefined);
  const [supplyChainDocumentMetadata, setSupplyChainDocumentMetadata] =
    React.useState<DocumentMetadata | undefined>(undefined);
  // Used to track state of files attached by the seller on the DocumentUploadPage
  const [documentTypeToFileMetadataMap, setDocumentTypeToFileMetadataMap] =
    React.useState<Map<DocumentType, FileMetadata[]>>(
      new Map<DocumentType, FileMetadata[]>(),
    );
  // State to denote if document upload button is enabled
  const [isDocumentsUploadButtonEnabled, setIsDocumentsUploadButtonEnabled] =
    React.useState<boolean>(false);
  // State to denote document upload button is clicked
  const [isDocumentsUploadButtonClicked, setIsDocumentsUploadButtonClicked] =
    React.useState<boolean>(false);
  const [shouldDisplayErrorMessage, setShouldDisplayErrorMessage] =
    React.useState<boolean>(false);
  const [documentUploadErrorMessage, setDocumentUploadErrorMessage] =
    React.useState<string>('');
  const [shouldDisplaySuccessMessage, setShouldDisplaySuccessMessage] =
    React.useState<boolean>(false);
  const [
    shouldReloadLatestDocumentMetadata,
    setShouldReloadLatestDocumentMetadata,
  ] = React.useState<boolean>(false);
  const [isIpvInitiated, setIsIpvInitiated] = React.useState<boolean>(false);
  const [isRequiredDocumentsUploaded, setIsRequiredDocumentsUploaded] =
    React.useState<boolean>(false);
  const [
    documentTypeToAttachmentErrorStatusMap,
    setDocumentTypeToAttachmentErrorStatusMap,
  ] = React.useState<Map<DocumentType, boolean>>(
    new Map<DocumentType, boolean>([
      [DocumentType.GOVERNMENT_ID, false],
      [DocumentType.BANK_STATEMENT, false],
      [DocumentType.SUPPLY_CHAIN, false],
      [DocumentType.REGISTRATION, false],
    ]),
  );

  const BACKEND_ERROR_CODE_TO_ERROR_MESSAGE_KEY_MAPPING = new Map([
    ['NOT_ALL_REQUIRED_DOCUMENTS_UPLOADED', 'submit-all-docs-error-message'],
    [
      'ACTIVE_INVESTIGATION_DOES_NOT_EXIST',
      'no-active-investigation-error-message',
    ],
    ['SELLER_NOT_ALLOW_LISTED', 'not-allow-listed-error-message'],
  ]);
  const FILE_UPLOAD_ERROR_CODE_TO_ERROR_MESSAGE_KEY_MAPPING = new Map([
    ['UNACCEPTED_FILE_FORMAT', 'unaccepted-file-format-error-banner-message'],
  ]);
  const PMET_METHOD_NAME = 'DocumentUploadPage';
  const PMET_RENDER_METRIC_NAME = 'render';
  const PMET_UPLOAD_DOCUMENT_COUNT_METRIC_NAME = 'uploaddocumentcount';
  const PMET_UPLOAD_DOCUMENT_SUCCESS_METRIC_NAME = 'uploaddocumentsuccess';
  const PMET_UPLOAD_DOCUMENT_FAILURE_METRIC_NAME = 'uploaddocumentfailure';
  const PMET_UPLOAD_DOCUMENT_VALIDATION_EXCEPTION_METRIC_NAME =
    'uploaddocumentvalidationexception';
  const PMET_UPLOAD_DOCUMENT_LATENCY_METRIC_NAME = 'uploaddocumentlatency';
  const PMET_UPLOAD_DOCUMENT_API_RETRY_COUNT = 'uploaddocumentapiretrycount';
  const PMET_UPLOAD_DOCUMENT_500_STATUS_CODE_COUNT =
    'uploaddocument500httpstatuscodecount';
  const PMET_UPLOAD_DOCUMENT_API_TIMEOUT_COUNT =
    'uploaddocumentapitimeoutcount';
  const PMET_UPLOAD_DOCUMENT_API_ABORT_ERROR_COUNT =
    'uploaddocumentapiaborterrorcount';
  const PMET_SUBMIT_ALL_DOCUMENTS_BUTTON_CLICK_METRIC_NAME =
    'submitalldocumentsbuttonclick';
  const PMET_SUBMIT_ALL_DOCUMENTS_BUTTON_CLICK_SUCCESS_METRIC_NAME =
    'submitalldocumentsbuttonclicksuccess';
  const PMET_INITIATE_IPV_BUTTON_CLICK_METRIC_NAME = 'initiateipvbuttonclick';
  const PMET_INITIATE_IPV_BUTTON_CLICK_SUCCESS_METRIC_NAME =
    'initiateipvbuttonclicksuccess';
  const PMET_INITIATE_IPV_BUTTON_CLICK_FAILURE_METRIC_NAME =
    'initiateipvbuttonclickfailure';
  const PMET_INITIATE_IPV_BUTTON_CLICK_VALIDATION_EXCEPTION_METRIC_NAME =
    'initiateipvbuttonclickvalidationexception';
  const PMET_INITIATE_IPV_BUTTON_CLICK_LATENCY_METRIC_NAME =
    'initiateipvbuttonclicklatency';
  const PMET_INITIATE_IPV_API_RETRY_COUNT = 'initiateipvapiretrycount';
  const PMET_INITIATE_IPV_500_STATUS_CODE_COUNT =
    'initiateipv500httpstatuscodecount';
  const PMET_INITIATE_IPV_API_TIMEOUT_COUNT = 'initiateipvapitimeoutcount';
  const PMET_INITIATE_IPV_API_ABORT_ERROR_COUNT =
    'initiateipvapiaborterrorcount';
  const PMET_GET_LATEST_DOCUMENTS_METADATA_COUNT_METRIC_NAME =
    'getlatestdocumentsmetadatacount';
  const PMET_GET_LATEST_DOCUMENTS_METADATA_SUCCESS_METRIC_NAME =
    'getlatestdocumentsmetadatasuccess';
  const PMET_GET_LATEST_DOCUMENTS_METADATA_FAILURE_METRIC_NAME =
    'getlatestdocumentsmetadatafailure';
  const PMET_GET_LATEST_DOCUMENTS_METADATA_VALIDATION_EXCEPTION_METRIC_NAME =
    'getlatestdocumentsmetadatavalidationexception';
  const PMET_GET_LATEST_DOCUMENTS_METADATA_LATENCY_METRIC_NAME =
    'getlatestdocumentsmetadatalatency';
  const PMET_GET_LATEST_DOCUMENTS_METADATA_API_RETRY_COUNT =
    'getlatestdocumentsmetadataapiretrycount';
  const PMET_GET_LATEST_DOCUMENTS_METADATA_500_STATUS_CODE_COUNT =
    'getlatestdocumentsmetadata500httpstatuscodecount';
  const PMET_GET_LATEST_DOCUMENTS_METADATA_API_TIMEOUT_COUNT =
    'getlatestdocumentsmetadataapitimeoutcount';
  const PMET_GET_LATEST_DOCUMENTS_METADATA_API_ABORT_ERROR_COUNT =
    'getlatestdocumentsmetadataapiaborterrorcount';

  const setDocumentUploadErrorMessageState = (errorMessageString: string) => {
    setShouldDisplayErrorMessage(true);
    setDocumentUploadErrorMessage(bundle.getMessage(errorMessageString));
    setShouldDisplaySuccessMessage(false);
  };

  const setDocumentsMetadata = (response: AjaxResponse, startTime: number) => {
    publishTimerMonitorMetric(
      PMET_METHOD_NAME,
      PMET_GET_LATEST_DOCUMENTS_METADATA_LATENCY_METRIC_NAME,
      startTime,
    );
    if (response.ok) {
      publishCounterMonitorMetric(
        PMET_METHOD_NAME,
        PMET_GET_LATEST_DOCUMENTS_METADATA_SUCCESS_METRIC_NAME,
      );
      const getLatestDocumentsMetadataResponse =
        response.data as GetLatestDocumentsMetadataResponse;
      const documentMetadataList =
        getLatestDocumentsMetadataResponse.documentMetadataList;

      documentMetadataList.map((documentMetadata) => {
        if (documentMetadata.documentType === DocumentType.GOVERNMENT_ID) {
          setIdentityDocumentMetadata(documentMetadata);
        }
        if (documentMetadata.documentType === DocumentType.BANK_STATEMENT) {
          setBankStatementDocumentMetadata(documentMetadata);
        }
        if (documentMetadata.documentType === DocumentType.REGISTRATION) {
          setBusinessRegistrationDocumentMetadata(documentMetadata);
        }
        if (documentMetadata.documentType === DocumentType.SUPPLY_CHAIN) {
          setSupplyChainDocumentMetadata(documentMetadata);
        }
      });
      setIsIpvInitiated(!!getLatestDocumentsMetadataResponse.ipvValidationId);
      setIsRequiredDocumentsUploaded(
        getLatestDocumentsMetadataResponse.requiredDocumentsUploaded,
      );
    } else {
      if (response.httpStatusCode === 500) {
        publishCounterMonitorMetric(
          PMET_METHOD_NAME,
          PMET_GET_LATEST_DOCUMENTS_METADATA_500_STATUS_CODE_COUNT,
        );
        throw new Error(
          `Error occurred while calling GetLatestDocumentsMetaData API with status code: ${response.httpStatusCode}`,
        );
      }
      logError(
        `GetLatestDocumentsMetaData failed with status code ${response.httpStatusCode}`,
      );
      publishCounterMonitorMetric(
        PMET_METHOD_NAME,
        PMET_GET_LATEST_DOCUMENTS_METADATA_VALIDATION_EXCEPTION_METRIC_NAME,
      );
      if (
        response.data?.message &&
        BACKEND_ERROR_CODE_TO_ERROR_MESSAGE_KEY_MAPPING.has(
          response.data.message,
        )
      ) {
        const errorStringKey =
          BACKEND_ERROR_CODE_TO_ERROR_MESSAGE_KEY_MAPPING.get(
            response.data.message,
          )!;
        setDocumentUploadErrorMessageState(errorStringKey);
      }
    }
  };

  const invokeGetLatestDocumentsMetadataAPI = (
    startTime: number,
    retryCount: number,
  ) => {
    const documentMetadataResponse = get(
      GET_LATEST_DOCUMENTS_METADATA_URL,
      GET_LATEST_DOCUMENTS_METADATA_API_TIMEOUT,
    );
    documentMetadataResponse
      .then((response: AjaxResponse) =>
        setDocumentsMetadata(response, startTime),
      )
      .catch((ex: Error) => {
        if (ex.name === 'AbortError') {
          logError(
            `Call to GetLatestDocumentsMetadata API timed out with remaining retries ${retryCount}`,
          );
          publishTimerMonitorMetric(
            PMET_METHOD_NAME,
            PMET_GET_LATEST_DOCUMENTS_METADATA_API_TIMEOUT_COUNT,
            startTime,
          );
          publishCounterMonitorMetric(
            PMET_METHOD_NAME,
            PMET_GET_LATEST_DOCUMENTS_METADATA_API_ABORT_ERROR_COUNT,
          );
        }

        if (retryCount < 1) {
          logError(
            `Call to GetLatestDocumentsMetadata API failed with no retries left and with exception ${ex}`,
          );
          publishTimerMonitorMetric(
            PMET_METHOD_NAME,
            PMET_GET_LATEST_DOCUMENTS_METADATA_LATENCY_METRIC_NAME,
            startTime,
          );
          publishCounterMonitorMetric(
            PMET_METHOD_NAME,
            PMET_GET_LATEST_DOCUMENTS_METADATA_FAILURE_METRIC_NAME,
          );
        } else {
          publishCounterMonitorMetric(
            PMET_METHOD_NAME,
            PMET_GET_LATEST_DOCUMENTS_METADATA_API_RETRY_COUNT,
          );
          logError(
            `Call to GetLatestDocumentsMetadata API failed with remaining retries ${retryCount} and with exception ${ex}`,
          );
          invokeGetLatestDocumentsMetadataAPI(startTime, retryCount - 1);
        }
      });
  };

  const getDocumentsMetadata = () => {
    publishCounterMonitorMetric(
      PMET_METHOD_NAME,
      PMET_GET_LATEST_DOCUMENTS_METADATA_COUNT_METRIC_NAME,
    );
    const startTime = new Date().getTime();
    invokeGetLatestDocumentsMetadataAPI(startTime, DEFAULT_API_RETRY_COUNT);
  };

  useEffect(() => {
    // Retrieve latest uploaded documents metadata
    getDocumentsMetadata();
  }, []);

  useEffect(() => {
    if (!shouldReloadLatestDocumentMetadata) return;
    // Retrieve latest uploaded documents metadata
    getDocumentsMetadata();
    setShouldReloadLatestDocumentMetadata(false);
  }, [shouldReloadLatestDocumentMetadata]);

  const filterOutDuplicateFilesInFileMetadata = (
    fileMetadataList: FileMetadata[],
    files: File[],
  ) => {
    fileMetadataList = fileMetadataList.filter(
      (fileMetadata) =>
        !files.find((file) => fileMetadata.file.name === file.name),
    );

    return fileMetadataList;
  };

  const updateDocumentTypeAttachmentErrorStatus = (
    documentType: DocumentType,
    newStatus: boolean,
  ) => {
    const tempDocumentTypeToAttachmentErrorStatusMap = new Map(
      documentTypeToAttachmentErrorStatusMap,
    );
    tempDocumentTypeToAttachmentErrorStatusMap.set(documentType, newStatus);
    setDocumentTypeToAttachmentErrorStatusMap(
      tempDocumentTypeToAttachmentErrorStatusMap,
    );
  };

  const handleRemovedFiles = (
    removedFiles: File[],
    documentType: DocumentType,
  ) => {
    documentTypeToFileMetadataMap.forEach(
      (fileMetadataList, fileMetadataDocumentType) => {
        if (fileMetadataDocumentType.valueOf() !== documentType.valueOf()) {
          return;
        }
        // Filter out the removed file by the seller from the state variable
        fileMetadataList = filterOutDuplicateFilesInFileMetadata(
          fileMetadataList,
          removedFiles,
        );
        const tempDocumentTypeToFileMetadataMap = documentTypeToFileMetadataMap;
        if (fileMetadataList.length > 0) {
          tempDocumentTypeToFileMetadataMap.set(
            fileMetadataDocumentType,
            fileMetadataList,
          );
          setDocumentTypeToFileMetadataMap(tempDocumentTypeToFileMetadataMap);
          updateDocumentTypeAttachmentErrorStatus(
            documentType,
            isAnyFileSizeZero(fileMetadataList),
          );
        } else {
          tempDocumentTypeToFileMetadataMap.delete(fileMetadataDocumentType);
          setDocumentTypeToFileMetadataMap(tempDocumentTypeToFileMetadataMap);
          updateDocumentTypeAttachmentErrorStatus(
            documentType,
            isAnyFileSizeZero(fileMetadataList),
          );
        }
      },
    );

    const globalFileMetadataMap = Array.from(
      documentTypeToFileMetadataMap.values(),
    ).flatMap((fileMetadataList) => fileMetadataList);

    setIsDocumentsUploadButtonEnabled(
      globalFileMetadataMap.length !== 0 &&
        areFileExtensionsSupported(globalFileMetadataMap) &&
        !isAnyFileSizeZero(globalFileMetadataMap),
    );
  };

  const getFileMetadata = (
    file: File,
    documentType: DocumentType,
  ): FileMetadata => ({
    documentType: documentType.valueOf(),
    uuid: uuidv4(),
    file,
  });

  const handleReplacedFiles = (
    replacedFiles: File[],
    documentType: DocumentType,
  ) => {
    documentTypeToFileMetadataMap.forEach(
      (fileMetadataList, fileMetadataDocumentType) => {
        if (fileMetadataDocumentType.valueOf() !== documentType.valueOf()) {
          return;
        }
        // Filter out the previous file metadata of the replaced files from the state variable
        // and update the state variable with the replaced file metadata
        fileMetadataList = filterOutDuplicateFilesInFileMetadata(
          fileMetadataList,
          replacedFiles,
        );
        replacedFiles.map((replacedFile) => {
          fileMetadataList.push(
            getFileMetadata(replacedFile, fileMetadataDocumentType),
          );
        });

        const tempDocumentTypeToFileMetadataMap = documentTypeToFileMetadataMap;
        tempDocumentTypeToFileMetadataMap.set(
          fileMetadataDocumentType,
          fileMetadataList,
        );
        setDocumentTypeToFileMetadataMap(tempDocumentTypeToFileMetadataMap);
        updateDocumentTypeAttachmentErrorStatus(
          documentType,
          isAnyFileSizeZero(fileMetadataList),
        );
      },
    );

    const globalFileMetadataMap = Array.from(
      documentTypeToFileMetadataMap.values(),
    ).flatMap((fileMetadataList) => fileMetadataList);

    setIsDocumentsUploadButtonEnabled(
      globalFileMetadataMap.length !== 0 &&
        areFileExtensionsSupported(globalFileMetadataMap) &&
        !isAnyFileSizeZero(globalFileMetadataMap),
    );
  };

  const handleAttachedFiles = (
    attachedFiles: File[],
    documentType: DocumentType,
  ) => {
    let fileMetadataList: FileMetadata[] = [];
    attachedFiles.map((attachedFile) => {
      fileMetadataList.push(getFileMetadata(attachedFile, documentType));
    });

    if (documentTypeToFileMetadataMap.has(documentType)) {
      fileMetadataList = fileMetadataList.concat(
        documentTypeToFileMetadataMap.get(documentType)!,
      );
    }

    const tempDocumentTypeToFileMetadataMap = documentTypeToFileMetadataMap;
    tempDocumentTypeToFileMetadataMap.set(documentType, fileMetadataList);
    setDocumentTypeToFileMetadataMap(tempDocumentTypeToFileMetadataMap);
    updateDocumentTypeAttachmentErrorStatus(
      documentType,
      isAnyFileSizeZero(fileMetadataList),
    );

    const globalFileMetadataMap = Array.from(
      documentTypeToFileMetadataMap.values(),
    ).flatMap((fileMetadataList) => fileMetadataList);

    setIsDocumentsUploadButtonEnabled(
      globalFileMetadataMap.length !== 0 &&
        areFileExtensionsSupported(globalFileMetadataMap) &&
        !isAnyFileSizeZero(globalFileMetadataMap),
    );
  };

  const resetDocumentsUploadButtonState = () => {
    setIsDocumentsUploadButtonClicked(false);
  };

  const resetErrorMessageState = () => {
    setShouldDisplayErrorMessage(false);
    setDocumentUploadErrorMessage('');
  };

  const processFileUploadResponse = (
    response: AjaxResponse,
    uploadedFileMetadata: FileMetadata,
    startTime: number,
  ) => {
    publishTimerMonitorMetric(
      PMET_METHOD_NAME,
      PMET_UPLOAD_DOCUMENT_LATENCY_METRIC_NAME,
      startTime,
    );
    if (response.ok) {
      publishCounterMonitorMetric(
        PMET_METHOD_NAME,
        PMET_UPLOAD_DOCUMENT_SUCCESS_METRIC_NAME,
      );
      // Remove successfully uploaded file from the `documentTypeToFileMetadataMap` state variable
      const fileMetadataList = documentTypeToFileMetadataMap.get(
        uploadedFileMetadata.documentType as DocumentType,
      )!;

      const filteredFileMetadataList = fileMetadataList.filter(
        (fileMetadata) => fileMetadata.uuid !== uploadedFileMetadata.uuid,
      );
      const tempDocumentTypeToFileMetadataMap = documentTypeToFileMetadataMap;
      if (filteredFileMetadataList.length > 0) {
        tempDocumentTypeToFileMetadataMap.set(
          uploadedFileMetadata.documentType as DocumentType,
          filteredFileMetadataList,
        );
      } else {
        tempDocumentTypeToFileMetadataMap.delete(
          uploadedFileMetadata.documentType as DocumentType,
        );
      }
      setDocumentTypeToFileMetadataMap(tempDocumentTypeToFileMetadataMap);

      // Reset state variables when all documents have been uploaded successfully
      if (tempDocumentTypeToFileMetadataMap.size === 0) {
        publishCounterMonitorMetric(
          PMET_METHOD_NAME,
          PMET_SUBMIT_ALL_DOCUMENTS_BUTTON_CLICK_SUCCESS_METRIC_NAME,
        );
        setDocumentTypeToFileMetadataMap(
          new Map<DocumentType, FileMetadata[]>(),
        );
        setShouldReloadLatestDocumentMetadata(true);
        resetErrorMessageState();
        setShouldDisplaySuccessMessage(true);
        resetDocumentsUploadButtonState();
      }
    } else {
      if (response.httpStatusCode === 500) {
        publishCounterMonitorMetric(
          PMET_METHOD_NAME,
          PMET_UPLOAD_DOCUMENT_500_STATUS_CODE_COUNT,
        );
        throw new Error(
          `Error occurred while calling UploadDocument API with status code: ${response.httpStatusCode}`,
        );
      }
      publishCounterMonitorMetric(
        PMET_METHOD_NAME,
        PMET_UPLOAD_DOCUMENT_VALIDATION_EXCEPTION_METRIC_NAME,
      );
      logError(
        `UploadDocuments() failed with response status ${response.httpStatusCode}`,
      );
      let errorStringKey = 'document-upload-error-banner-message';
      if (
        response.data?.message &&
        FILE_UPLOAD_ERROR_CODE_TO_ERROR_MESSAGE_KEY_MAPPING.has(
          response.data.message,
        )
      ) {
        errorStringKey =
          FILE_UPLOAD_ERROR_CODE_TO_ERROR_MESSAGE_KEY_MAPPING.get(
            response.data.message,
          )!;
      }
      setDocumentUploadErrorMessageState(errorStringKey);
      resetDocumentsUploadButtonState();
    }
  };

  const invokeUploadDocumentAPI = (
    startTime: number,
    retryCount: number,
    fileMetadata: FileMetadata,
  ) => {
    const queryParam: Record<string, string> = {
      'File-Type': fileMetadata.file.type,
      'Document-Type': fileMetadata.documentType,
    };
    const fileUploadResponse = postFileUpload(
      UPLOAD_DOCUMENT,
      UPLOAD_DOCUMENT_API_TIMEOUT,
      fileMetadata.file,
      queryParam,
    );
    fileUploadResponse
      .then((response: AjaxResponse) => {
        processFileUploadResponse(response, fileMetadata, startTime);
      })
      .catch((ex: Error) => {
        if (ex.name === 'AbortError') {
          logError(
            `Call to UploadDocument API timed out with remaining retries ${retryCount}`,
          );
          publishTimerMonitorMetric(
            PMET_METHOD_NAME,
            PMET_UPLOAD_DOCUMENT_API_TIMEOUT_COUNT,
            startTime,
          );
          publishCounterMonitorMetric(
            PMET_METHOD_NAME,
            PMET_UPLOAD_DOCUMENT_API_ABORT_ERROR_COUNT,
          );
        }

        if (retryCount < 1) {
          publishTimerMonitorMetric(
            PMET_METHOD_NAME,
            PMET_UPLOAD_DOCUMENT_LATENCY_METRIC_NAME,
            startTime,
          );
          publishCounterMonitorMetric(
            PMET_METHOD_NAME,
            PMET_UPLOAD_DOCUMENT_FAILURE_METRIC_NAME,
          );
          logError(
            `Call to UploadDocument API failed with no retries left and with exception ${ex}`,
          );
          resetDocumentsUploadButtonState();
          setDocumentUploadErrorMessageState(
            'document-upload-error-banner-message',
          );
        } else {
          publishCounterMonitorMetric(
            PMET_METHOD_NAME,
            PMET_UPLOAD_DOCUMENT_API_RETRY_COUNT,
          );
          logError(
            `Call to UploadDocument API failed with remaining retries ${retryCount} and with exception ${ex}`,
          );
          invokeUploadDocumentAPI(startTime, retryCount - 1, fileMetadata);
        }
      });
  };

  const uploadDocuments = () => {
    logInfo(
      'Seller has clicked the `Submit all documents` button to submit their documents',
    );
    const startTime = new Date().getTime();
    publishCounterMonitorMetric(
      PMET_METHOD_NAME,
      PMET_SUBMIT_ALL_DOCUMENTS_BUTTON_CLICK_METRIC_NAME,
    );
    setIsDocumentsUploadButtonClicked(true);

    if (documentTypeToFileMetadataMap.size < 1) {
      logError(
        'documentTypeToFileMetadataMap state is empty which indicates user has not attached any files',
      );
      resetDocumentsUploadButtonState();
      setDocumentUploadErrorMessageState(
        'submit-documents-error-banner-message',
      );
      return;
    }
    documentTypeToFileMetadataMap.forEach((fileMetadataList) => {
      fileMetadataList.map((fileMetadata) => {
        publishCounterMonitorMetric(
          PMET_METHOD_NAME,
          PMET_UPLOAD_DOCUMENT_COUNT_METRIC_NAME,
        );
        invokeUploadDocumentAPI(
          startTime,
          DEFAULT_API_RETRY_COUNT,
          fileMetadata,
        );
      });
    });
  };

  const processInitiateIpvResponse = (
    response: AjaxResponse,
    startTime: number,
  ) => {
    publishTimerMonitorMetric(
      PMET_METHOD_NAME,
      PMET_INITIATE_IPV_BUTTON_CLICK_LATENCY_METRIC_NAME,
      startTime,
    );
    if (response.ok) {
      publishCounterMonitorMetric(
        PMET_METHOD_NAME,
        PMET_INITIATE_IPV_BUTTON_CLICK_SUCCESS_METRIC_NAME,
      );
      logInfo('IPV has been successfully initiated for seller');
      MarioUtil.submitPage(documentUploadPageWidget.current);
    } else {
      if (response.httpStatusCode === 500) {
        publishCounterMonitorMetric(
          PMET_METHOD_NAME,
          PMET_INITIATE_IPV_500_STATUS_CODE_COUNT,
        );
        throw new Error(
          `Error occurred while calling InitiateIpv API with status code: ${response.httpStatusCode}`,
        );
      }
      publishCounterMonitorMetric(
        PMET_METHOD_NAME,
        PMET_INITIATE_IPV_BUTTON_CLICK_VALIDATION_EXCEPTION_METRIC_NAME,
      );
      logError(
        `InitiateIpv failed with status code ${response.httpStatusCode}`,
      );
      let errorStringKey = 'proceed-to-ipv-error-banner-message';
      if (
        response.data?.message &&
        BACKEND_ERROR_CODE_TO_ERROR_MESSAGE_KEY_MAPPING.has(
          response.data.message,
        )
      ) {
        errorStringKey = BACKEND_ERROR_CODE_TO_ERROR_MESSAGE_KEY_MAPPING.get(
          response.data.message,
        )!;
      }
      setDocumentUploadErrorMessageState(errorStringKey);
    }
  };

  const invokeInitiateIPVAPI = (startTime: number, retryCount: number) => {
    const initiateIpvResponse = fetchPost(
      INITIATE_IPV,
      INITIATE_IPV_API_TIMEOUT,
    );

    initiateIpvResponse
      .then((response: AjaxResponse) => {
        processInitiateIpvResponse(response, startTime);
      })
      .catch((ex: Error) => {
        if (ex.name === 'AbortError') {
          logError(
            `Call to InitiateIpv API timed out with remaining retries ${retryCount}`,
          );
          publishTimerMonitorMetric(
            PMET_METHOD_NAME,
            PMET_INITIATE_IPV_API_TIMEOUT_COUNT,
            startTime,
          );
          publishCounterMonitorMetric(
            PMET_METHOD_NAME,
            PMET_INITIATE_IPV_API_ABORT_ERROR_COUNT,
          );
        }

        if (retryCount < 1) {
          publishTimerMonitorMetric(
            PMET_METHOD_NAME,
            PMET_INITIATE_IPV_BUTTON_CLICK_LATENCY_METRIC_NAME,
            startTime,
          );
          publishCounterMonitorMetric(
            PMET_METHOD_NAME,
            PMET_INITIATE_IPV_BUTTON_CLICK_FAILURE_METRIC_NAME,
          );
          logError(
            `Call to InitiateIpv API failed with no retries left and with exception ${ex}`,
          );
          setDocumentUploadErrorMessageState(
            'proceed-to-ipv-error-banner-message',
          );
        } else {
          publishCounterMonitorMetric(
            PMET_METHOD_NAME,
            PMET_INITIATE_IPV_API_RETRY_COUNT,
          );
          logError(
            `Call to InitiateIpv API failed with remaining retries ${retryCount} and with exception ${ex}`,
          );
          invokeInitiateIPVAPI(startTime, retryCount - 1);
        }
      });
  };

  const initiateIPV = () => {
    logInfo(
      'Seller has clicked the `Proceed to Identity Verification call` button to proceed to the Identity Verification call page',
    );
    const startTime = new Date().getTime();
    publishCounterMonitorMetric(
      PMET_METHOD_NAME,
      PMET_INITIATE_IPV_BUTTON_CLICK_METRIC_NAME,
    );
    invokeInitiateIPVAPI(startTime, DEFAULT_API_RETRY_COUNT);
  };

  logInfo(`Rendering DocumentUploadPageComponent UI elements`);
  publishCounterMonitorMetric(PMET_METHOD_NAME, PMET_RENDER_METRIC_NAME);
  return (
    <div id="document-upload-page" ref={documentUploadPageWidget}>
      <div
        className={
          isDocumentsUploadButtonClicked
            ? 'document-upload-page-spinner-background'
            : ''
        }
      >
        <h1 className="title-padding">
          {bundle.getMessage('document-upload-page-title-text')}
        </h1>
        <KatAlert
          id="documentUploadInfoMesageAlert"
          variant="info"
          description={bundle.getMessage(
            'single-document-upload-alert-message',
          )}
          persistent
        />
        <div className="document-upload-page-card-padding">
          <KatCard>
            <DocumentUploadUnit
              id="identity-document-upload-unit"
              title={bundle.getMessage('identity-doc-title-text')}
              description={bundle.getMessage('identity-doc-body')}
              documentMetadata={identityDocumentMetadata}
              documentType={DocumentType.GOVERNMENT_ID}
              handleAttachedFiles={handleAttachedFiles}
              handleReplacedFiles={handleReplacedFiles}
              handleRemovedFiles={handleRemovedFiles}
              attachmentError={
                documentTypeToAttachmentErrorStatusMap.get(
                  DocumentType.GOVERNMENT_ID,
                )!
              }
            />
            <DocumentUploadUnit
              id="bank-statement-upload-unit"
              title={bundle.getMessage('bank-statement-doc-title-text')}
              description={bundle.getMessage('bank-statement-doc-body')}
              documentMetadata={bankStatementDocumentMetadata}
              documentType={DocumentType.BANK_STATEMENT}
              handleAttachedFiles={handleAttachedFiles}
              handleReplacedFiles={handleReplacedFiles}
              handleRemovedFiles={handleRemovedFiles}
              attachmentError={
                documentTypeToAttachmentErrorStatusMap.get(
                  DocumentType.BANK_STATEMENT,
                )!
              }
            />
            <DocumentUploadUnit
              id="business-registration-upload-unit"
              title={bundle.getMessage('business-reg-doc-title-text')}
              description={bundle.getMessage('business-reg-doc-body')}
              documentMetadata={businessRegistrationDocumentMetadata}
              documentType={DocumentType.REGISTRATION}
              handleAttachedFiles={handleAttachedFiles}
              handleReplacedFiles={handleReplacedFiles}
              handleRemovedFiles={handleRemovedFiles}
              attachmentError={
                documentTypeToAttachmentErrorStatusMap.get(
                  DocumentType.REGISTRATION,
                )!
              }
            />
            <DocumentUploadUnit
              id="supply-chain-upload-unit"
              title={bundle.getMessage('supply-chain-doc-title-text')}
              description={bundle.getMessage('supply-chain-doc-body')}
              documentMetadata={supplyChainDocumentMetadata}
              documentType={DocumentType.SUPPLY_CHAIN}
              handleAttachedFiles={handleAttachedFiles}
              handleReplacedFiles={handleReplacedFiles}
              handleRemovedFiles={handleRemovedFiles}
              attachmentError={
                documentTypeToAttachmentErrorStatusMap.get(
                  DocumentType.SUPPLY_CHAIN,
                )!
              }
            />
            <KatButton
              id="documentPageSubmitButton"
              variant="primary"
              className="button-secondary-padding"
              label={bundle.getMessage('document-upload-submit-all-docs')}
              onClick={() => uploadDocuments()}
              disabled={isIpvInitiated || !isDocumentsUploadButtonEnabled}
            />
          </KatCard>
        </div>
        <KatAlert
          id="proceedToIpvInfoMesage"
          variant="info"
          description={bundle.getMessage('proceed-to-ipv-info-message')}
          persistent
        />
        <KatButton
          id="documentPageProceedButton"
          className="button-padding"
          variant="primary"
          label={bundle.getMessage('document-upload-proceed-to-ipv')}
          onClick={() => initiateIPV()}
          disabled={!isRequiredDocumentsUploaded}
        />
        <KatAlert
          id="documentUploadErrorAlert"
          description={documentUploadErrorMessage}
          variant="danger"
          dismissed={!shouldDisplayErrorMessage}
          onDismiss={() => resetErrorMessageState()}
        />
        <KatAlert
          id="documentUploadSuccessAlert"
          description={bundle.getMessage(
            'submit-documents-success-banner-message',
          )}
          variant="success"
          dismissed={!shouldDisplaySuccessMessage}
          onDismiss={() => setShouldDisplaySuccessMessage(false)}
        />
      </div>
      {isDocumentsUploadButtonClicked && (
        <div id="spinner" className="document-upload-spinner">
          <KatSpinner />
        </div>
      )}
    </div>
  );
};

export const DocumentUploadPage = withBundle('pages.documentUploadPage')(
  DocumentUploadPageComponent,
);
