import { html, property } from 'lit-element';
import { nothing } from 'lit-html';
import { ifNotNull } from '../../utils/directives';

import { KatLitElement, register } from '../../shared/base';
import baseStyles from '../../shared/base/base.lit.scss';
import { cleanDefaultSlot } from '../../shared/slot-utils';
import buttonStyles from './button.lit.scss';
/**
 * @component {kat-button} KatalButton A button offers users the opportunity to take an action. The button label tells the user what action will occur.
 * @status Production
 * @theme flo
 * @slot default Elements without a named slot attribute. Appears inside the button.
 * @slot icon Contents will be used as the icon of the button. Sets default alignment and margin.
 * @example Primary {"label": "Primary Button", "variant":"primary"}
 * @example Secondary {"label": "Secondary Button", "variant":"secondary"}
 * @example Tertiary {"label": "Tertiary Button", "variant": "tertiary"}
 * @example Link {"label": "Button as link", "variant":"link"}
 * @example Warning {"label": "Warning Button", "variant":"warning"}
 * @example Danger {"label": "Danger Button", "variant":"danger"}
 * @example Icon {
 * "content": "
 *  <kat-icon size=\"small\" slot=\"icon\" name=\"info\"></kat-icon>
 *   <div>
 *      Info Button with Icon
 *  </div>
 * "}
 * @example Loading { "variant": "secondary", "loading": true, "label": "Loading button label"}
 * @guideline Do Buttons are reserved for actions.
 * @guideline Do Use the primary button state for the most common or primary actions a customer will take on the page.
 * @guideline Do Use sentence case (not title case).
 * @guideline Do Labels should have a maximum of two words (three on rare occasions).
 * @guideline Dont Don't use more than one primary button per page.
 * @guideline Do Use tertiary buttons when more contrast is needed than can be provided by primary and secondary buttons.
 * @a11y {keyboard}
 * @a11y {sr}
 * @a11y {contrast}
 */

@register('kat-button')
export class KatButton extends KatLitElement {
  /** The text of the button that will be displayed to the user. The default slot can also be used to set the label. */
  @property()
  label?: string;

  /** The value of the button */
  @property()
  value?: string;

  /**
   * Specifies the type of button to be displayed. Defaults to "primary".
   * @enum {value} primary This is the default button type.
   * @enum {value} secondary Button type for a secondary action.
   * @enum {value} tertiary A lighter alternative button style.
   * @enum {value} link Looks like a hyperlink but emits the same events as other kat-buttons.
   * @enum {value} warning Button type indicating warning states/situations
   * @enum {value} danger Button type indicating danger states/situations
   */
  @property()
  variant = 'primary';

  /** If present, the button is not clickable. Does not work on the link variant. */
  @property()
  disabled?: boolean;

  /** Displays a spinning animation to indicate loading state - disables clicks. */
  @property()
  loading?: boolean;

  /**
   * Specifies the visual size of the button. Defaults to "base".
   * @enum {value} base This is the default button size.
   * @enum {value} small This is a smaller button
   */
  @property()
  size = 'base';

  /**
   * Specifies whether button should have input focus when the page loads. Only one element in a document can have this attribute.
   * @required false
   */
  @property()
  autofocus: boolean;

  /** The <form> element to associate the button with (its form owner). The value of this attribute must be the id of a <form> in the same document. */
  @property()
  form?: string;

  /**
   * The URL that processes the information submitted by the button.
   * Overrides the action attribute of the button's form owner. Does nothing if there is no form owner.
   */
  @property()
  formAction?: string;

  /**
   * If the button is a submit button (it's inside/associated with a <form> and doesn't have type="button"), specifies how to encode the form data that is submitted.
   * @enum {value} application/x-www-form-urlencoded The default if the attribute is not used.
   *  @enum {value} multipart/form-data Used to submit <input> elements with their type attributes set to file.
   * @enum {value} text/plain Specified as a debugging aid. Shouldn’t be used for real form submission.
   */
  @property()
  formEnctype?: string;

  /**
   * If the button is a submit button (it's inside/associated with a <form> and doesn't have type="button"), this attribute specifies the HTTP method used to submit the form.
   * @enum {value} post The data from the form is included in the body of the HTTP request when sent to the server. Use when the form contains information that shouldn’t be public, like login credentials.
   * @enum {value} get The form data appends  to the form's action URL, with a ? as a separator, and the resulting URL is sent to the server. Use this method when the form has no side effects, like search forms.
   */
  @property()
  formMethod?: string;

  /** If the button is a submit button, this Boolean attribute specifies that the form is not to be validated when it is submitted. If this attribute is specified, it overrides the novalidate attribute of the button's form owner. */
  @property()
  formNoValidate?: boolean;

  /**
   * If the button is a submit button, this attribute is a author-defined name or standardized, underscore-prefixed keyword indicating where to display the response from submitting the form. This is the name of, or keyword for, a browsing context (a tab, window, or <iframe>). If this attribute is specified, it overrides the target attribute of the button's form owner.
   * @enum {value} _self Load the response into the same browsing context as the current one. This is the default if the attribute is not specified.
   * @enum {value} _blank Load the response into a new unnamed browsing context — usually a new tab or window, depending on the user’s browser settings.
   * @enum {value} _parent Load the response into the parent browsing context of the current one. If there is no parent, this option behaves the same way as _self.
   * @enum {value} _top Load the response into the top-level browsing context (that is, the browsing context that is an ancestor of the current one, and has no parent). If there is no parent, this option behaves the same way as _self.
   */
  @property()
  formTarget?: string;

  /** The name of the button, submitted as a pair with the button’s value as part of the form data. */
  @property()
  name?: string;

  /**
   * The action a button performs. Defaults to "button".
   * @enum {value} button Clickable button
   * @enum {value} submit Clickable button that submits form data
   * @enum {value} reset Clickable button the clears a form
   */
  @property()
  type = 'button';

  /** The label used exclusively by screenreaders as a fallback if the regular label is not specified. */
  @property({ attribute: 'kat-aria-label' })
  katAriaLabel?: string;

  static get styles() {
    return [baseStyles, buttonStyles];
  }

  constructor() {
    super();
    cleanDefaultSlot(this);
  }

  firstUpdated() {
    // prevent clicks from happening when the button is disabled or loading
    this.addEventListener(
      'click',
      e => {
        this._handleClick(e);
      },
      true
    );
    // this.observeChildren(this, () => this.requestUpdate());
  }

  _handleClick(e) {
    if (this.disabled || this.loading) {
      e.preventDefault();
      e.stopImmediatePropagation();
      return;
    }
    if (this.type === 'submit' || this.type === 'reset') {
      e.preventDefault();
      const hiddenButton = this.shadowRoot.querySelector('.button').cloneNode();
      hiddenButton.style.display = 'none';
      this.parentNode.insertBefore(hiddenButton, this);
      hiddenButton.click();
      this.parentNode.removeChild(hiddenButton);
    }
  }

  createRenderRoot() {
    return this.attachShadow({ mode: 'open', delegatesFocus: true });
  }

  /**
   * TODO: remove the tabindex DIV after safari 14 bug is fixed: https://bugs.webkit.org/show_bug.cgi?id=215622
   * DIV is only used to grab focus when focus is "delegated". Safari crashed when the button element is focused
   */
  render() {
    return html`
      <button
        class="button"
        type=${ifNotNull(this.type)}
        value=${ifNotNull(this.value)}
        ?disabled=${this.disabled || this.loading}
        ?autofocus=${this.autofocus}
        formenctype=${ifNotNull(this.formEnctype)}
        form=${ifNotNull(this.form)}
        formaction=${ifNotNull(this.formAction)}
        formmethod=${ifNotNull(this.formMethod)}
        ?formnovalidate=${this.formNoValidate}
        formtarget=${ifNotNull(this.formTarget)}
        name=${ifNotNull(this.name)}
        aria-label=${ifNotNull(this.katAriaLabel)}
      >
        <div class="icon__container">
          ${this.loading
            ? html`<kat-spinner class="spinner" size="small"></kat-spinner>`
            : nothing}

          <span class="icon"><slot name="icon"></slot></span>
        </div>
        <div class="content">
          <slot>
            ${this.label ? html`<span>${this.label}</span>` : nothing}
          </slot>
        </div>
        <div tabindex="-1"></div>
      </button>
    `;
  }
}
