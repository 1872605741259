import { ACCEPTED_FILE_EXTENSIONS } from 'src/constants';
import { FileMetadata } from 'src/model/FileMetadata';

const isFileExtensionSupported = (file: File) => {
  if (!file) return false;

  const filename = file.name;
  const index = filename.lastIndexOf('.');
  if (index > 0 && index + 1 < filename.length) {
    const fileExtension = filename.substring(index).toLowerCase();
    return ACCEPTED_FILE_EXTENSIONS.includes(fileExtension);
  }

  return false;
};

const isFileSizeZero = (file: File) => !file || file.size === 0;

export const areFileExtensionsSupported = (
  fileMetadataList: FileMetadata[],
) => {
  for (const fileMetadata of fileMetadataList) {
    if (!isFileExtensionSupported(fileMetadata.file)) {
      return false;
    }
  }
  return true;
};

export const isAnyFileSizeZero = (fileMetadataList: FileMetadata[]) => {
  for (const fileMetadata of fileMetadataList) {
    if (isFileSizeZero(fileMetadata.file)) {
      return true;
    }
  }
  return false;
};
