import { withBundle, WithBundleProps } from '@amzn/react-arb-tools';
import { logInfo } from 'src/utils/logger/LoggerUtil';
import React from 'react';
import { KatLink } from '@amzn/katal-react';
import {
  MARIO_IPI_LANDING_PAGE_RELATIVE_URL,
  SELLER_SUPPORT_CONTACT_RELATIVE_URL,
  HTTPS_PROTOCOL,
} from 'src/constants';
import { publishCounterMonitorMetric } from 'src/utils/MetricUtil';

export const ErrorPageComponent = (props: WithBundleProps) => {
  logInfo('Loading ErrorPageComponent');
  const { bundle } = props;
  const IPI_LANDING_PAGE_URL =
    HTTPS_PROTOCOL +
    window.location.hostname +
    MARIO_IPI_LANDING_PAGE_RELATIVE_URL;
  const SELLER_SUPPORT_PAGE_LINK =
    HTTPS_PROTOCOL +
    window.location.hostname +
    SELLER_SUPPORT_CONTACT_RELATIVE_URL;
  const PMET_METHOD_NAME = 'IPIErrorPage';
  const PMET_RENDER_METRIC_NAME = 'render';

  logInfo('Rendering ErrorPageComponent UI elements');
  publishCounterMonitorMetric(PMET_METHOD_NAME, PMET_RENDER_METRIC_NAME);
  return (
    <div id="ipi-error-page">
      <span className="error-page-text">
        {bundle.getMessage('error-page-non-eligible-for-ipi-text')}
      </span>
      <span className="error-page-text">
        <div>{bundle.getMessage('error-page-troubleshoot-step-1-text')}</div>
        <div>
          <KatLink
            href={IPI_LANDING_PAGE_URL}
            label={IPI_LANDING_PAGE_URL}
            variant="link"
            className="error-page-link"
          />
        </div>
      </span>
      <span className="error-page-text">
        {bundle.getMessage('error-page-troubleshoot-step-2-text')}
      </span>
      <span className="error-page-text">
        {bundle.getMessage('error-page-contact-support-text-prefix') + ' '}
        <KatLink
          href={SELLER_SUPPORT_PAGE_LINK}
          label={bundle.getMessage('error-page-seller-support-text')}
          variant="link"
          className="error-page-link"
        />
        {' ' + bundle.getMessage('error-page-contact-support-text-suffix')}
      </span>
    </div>
  );
};

export const ErrorPage = withBundle('pages.errorPage')(ErrorPageComponent);
