import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { LandingPage } from 'src/components/ipi/LandingPage';
import { DocumentUploadPage } from 'src/components/ipi/DocumentUploadPage';
import { MARIO_IPI_WEBPAGE_PREFIX } from 'src/constants';
import { MarioAjaxHandler } from 'src/utils/ajax/MarioAjaxHandler';
import { logInfo } from 'src/utils/logger/LoggerUtil';
import { ErrorPage } from 'src/components/ipi/ErrorPage';

class AppComponent extends React.Component {
  render() {
    logInfo(`Rendering React AppComponent`);
    return (
      <Router>
        <Routes>
          <Route path="/landingPage" element={<LandingPage />} />
          <Route
            path="/documentUploadPage"
            element={<DocumentUploadPage {...MarioAjaxHandler} />}
          />
          <Route path="/errorPage" element={<ErrorPage />} />
          <Route
            path={`${MARIO_IPI_WEBPAGE_PREFIX}/verificationRequired/render`}
            element={<LandingPage />}
          />
          <Route
            path={`${MARIO_IPI_WEBPAGE_PREFIX}/documentUpload/render`}
            element={<DocumentUploadPage {...MarioAjaxHandler} />}
          />
          <Route
            path={`${MARIO_IPI_WEBPAGE_PREFIX}/errorPage/render`}
            element={<ErrorPage />}
          />
        </Routes>
      </Router>
    );
  }
}

export const App = AppComponent;
