import { KatalComponent, KatLitElement } from './base';

/**
 * Sentinel object signaling to `getFirstMatchingParent` that we want to find
 * the shadowRoot element
 */
const SHADOW_SENTINEL = {};

/**
 * Find the first dom node that matches the given `predicate` by walking up the
 * DOM. This method can return the `start` node if it matches.
 *
 * @param start The node to start the search from.
 * @param predicate A predicate function receiving the current DOM node, or a
 *     string. If a string is passed, it will be compared against elements using
 *     `matches`.
 * @param stopParent The node to stop the search at. If this is reached before a
 *     node is found, `null` is returned. Defaults to the `document`.
 *
 * @return The first matching node, or `null` if none is found before
 *     `stopParent` is reached.
 */
export function getFirstMatchingParent(
  start: HTMLElement,
  predicate: (el: HTMLElement) => boolean,
  stopParent: HTMLElement = document as unknown as HTMLElement
): HTMLElement | null {
  const lookingForShadowRoot = predicate === SHADOW_SENTINEL;

  for (
    let el = start;
    el !== stopParent && el;
    el = el.parentNode as HTMLElement
  ) {
    if (el instanceof ShadowRoot) {
      if (lookingForShadowRoot) return el;
      el = el.host as HTMLElement;
    }
    if (!lookingForShadowRoot && predicate(el)) {
      return el;
    }
  }

  return null;
}

export function getContainingShadowRoot(el: HTMLElement): ShadowRoot | null {
  return getFirstMatchingParent(
    el,
    SHADOW_SENTINEL as any
  ) as unknown as ShadowRoot;
}

export function createElement(
  tagName: string,
  attributes: Record<string, string> = {},
  children: HTMLElement[] = []
) {
  const ele = document.createElement(tagName);

  for (const a in attributes) {
    if (a === '__text') {
      ele.innerText = attributes[a];
    } else {
      ele.setAttribute(a, attributes[a]);
    }
  }

  children.forEach(c => ele.appendChild(c));

  return ele;
}

export function prependChild(parent: HTMLElement, child: HTMLElement) {
  if (parent.firstChild) {
    parent.insertBefore(child, parent.firstChild);
  } else {
    parent.appendChild(child);
  }
}

/**
 * Create an array of items by evaluating the given `cb` function for
 * every number from [0, `steps`).
 * @param steps The number of items to create.
 * @param cb Called for every step.
 * @return An array of results from calling `cb`.
 */
export function rangeMap<T>(steps: number, cb: (step: number) => T): T[] {
  const items = [];
  for (let i = 0; i < steps; ++i) {
    items.push(cb(i));
  }
  return items;
}

/**
 * Standalone function equivalent of calling `Object.hasOwnProperty`.
 * @param obj The object to check for a property on.
 * @param prop The property to check for.
 * @return Whether `obj` has its own `prop`.
 */
export function has(obj: any, prop: string) {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

/**
 * Breadth first search for a text node that isn't just whitespace and return it's content. Otherwise return null.
 * @param ele The element to search inside of.
 * @return The content of the first text node found or null.
 */
export function getContentOfFirstTextNode(ele: HTMLElement) {
  const children = Array.from(ele.childNodes);
  for (let c = 0; c < children.length; c++) {
    const child = children[c] as HTMLElement;
    // Return a text node that contains something besides whitespace.
    if (child.nodeType === child.TEXT_NODE) {
      if (child.textContent.trim() !== '') {
        return child.textContent;
      }
    }

    // Recursive
    const result = getContentOfFirstTextNode(child);
    if (result !== null) {
      return result;
    }
  }

  return null;
}

/**
 * Will try to find the element with the given ID by looking both globally on the page and in any
 * shadowRoots that are an ancestor of the source element.
 */
export function getElementByIdThroughShadow(source: HTMLElement, id: string) {
  const global = document.getElementById(id);
  if (global) return global;

  // Iterate through each ancestor with a shadowRoot
  let parent = getContainingShadowRoot(source.parentNode as HTMLElement);
  while (parent) {
    const local = parent.getElementById(id);
    if (local) return local;
    parent = getContainingShadowRoot(parent.host as HTMLElement);
  }

  return null;
}

/**
 * Attempts to focus the given element, returns true if succeeds, false if not. Also handles
 * webcomponents that are likely to focus an inner element rather than the root. Also handles
 * shadow roots that have their own activeElement.
 * @param ele The element to focus.
 * @return Whether the element was focused or not.
 */
export function attemptToFocus(ele: HTMLElement) {
  if (!ele) return false;

  try {
    ele.focus();
  } catch (_) {
    // Omitted as error is meaningless. We really only care that focus was attempted. Below we
    // do a comprehensive check to see if it worked.
  }

  if (document.activeElement === ele) return true;

  if (ele.shadowRoot?.activeElement) return true;

  if (isKatalComponent(ele) && ele.contains(document.activeElement)) {
    return true;
  }

  return false;
}

/**
 * Returns the KatalComponent that currently has focus (because an inner element has focus)
 * otherwise just returns the current active element.
 * @param omit Optional element to omit when searching for Katal components. Useful to omit the
 * current component as it would always be returned if the activeElement is a child.
 */
export function getActiveComponentOrElement(omit: HTMLElement): HTMLElement {
  const active = document.activeElement as HTMLElement;
  if (!active) return null;

  const comp = getFirstMatchingParent(active, par => {
    if (par === omit) return false;
    if (isKatalComponent(par)) {
      // If this component has a shadowRoot then this element (from document.activeElement) must be
      // in the lightDOM as otherwise it would only appears as element.shadowRoot.activeElement.
      return !par.shadowRoot;
    }
    return false;
  });

  return comp ? comp : active;
}

/**
 * Visits all descendant nodes by calling the visitor function for each node. Traverses depth
 * first. Will stop when the visitor callback returns true. If inReverse is true then traversal
 * happens backwards from the last child forward.
 * @param root Element whose descendants to visit.
 * @param visitor Callback function called with each node visited. Can return true to cause
 * traversal to stop.
 * @param inReverse Optionally determine if traversal should happen in reverse.
 * @return Returns true if the traversal was ended by the visitor callback. Otherwise returns false.
 */
export function visitChildrenDepthFirst(
  root: HTMLElement,
  visitor: (HTMLElement) => boolean,
  inReverse = false
) {
  const children = Array.from(root.children) as HTMLElement[];
  if (inReverse) {
    children.reverse();
  }
  for (const child of children) {
    if (visitor(child) || visitChildrenDepthFirst(child, visitor, inReverse)) {
      return true;
    }
  }
  return false;
}

/**
 * Returns true if the given element is a KatalComponent, a webcomponent using our base class.
 * @param ele The element to check.
 * @return True if the given element is a KatalComponent. Otherwise, false.
 */
export function isKatalComponent(ele: HTMLElement): boolean {
  return ele instanceof KatalComponent || ele instanceof KatLitElement;
}

export function hasChildren(el?: HTMLElement): boolean {
  return el && !!el.children.length;
}

/**
 * Returns the first non-null result when calling the given `fns` in order. Null
 * may be returned if none of the fns return a non-null value.
 * @param fns An array of functions to call.
 * @return The first non-null result.
 */
export function firstNonNull<T>(fns: (() => T)[]): T {
  let result: T;
  fns.some(fn => {
    result = fn();
    return result != null;
  });
  return result;
}

/**
 * Checks if the given event `e` originated inside the `target` element. If the
 * event is composed, then it walks the composed path. Simply checking if
 * `target.contains(e.target)` is not sufficient, because if `target` is nested
 * inside of another element's shadowRoot, then `e.target` will be the
 * shadowRoot's host.
 */
export function eventOriginatedInside(target: Element, e: Event): boolean {
  if (e.composed) {
    return e.composedPath().some(el => el === target);
  }
  return target.contains(e.target as Node);
}

export function eventToPromise(el: Element, event: string) {
  return new Promise(resolve => {
    el.addEventListener(event, resolve);
  });
}

export class _Emitter<T = void> {
  listeners = new Set<(t: T) => void>();

  addListener(listener: (t: T) => void) {
    this.listeners.add(listener);
  }

  removeListener(listener: (t: T) => void) {
    this.listeners.delete(listener);
  }

  emit = (t: T) => {
    for (const subscription of this.listeners.values()) subscription(t);
    return true;
  };
}

export function truncateString(message, max, wordBreak = false) {
  if (message.length <= max) {
    return message;
  }
  const truncated = message.substr(0, max);
  if (wordBreak) {
    return truncated.substr(0, truncated.lastIndexOf('')) + '...';
  }
  return truncated + '...';
}

// https://caniuse.com/?search=navigator - safe to suppress this eslint error
/* eslint-disable compat/compat */
export function isSafari(): boolean {
  return navigator.vendor.includes('Apple');
}

export function isChrome(): boolean {
  return navigator.vendor.includes('Google');
}

export function isFirefox(): boolean {
  return navigator.vendor === '';
}
/* eslint-enable compat/compat */
